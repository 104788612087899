import React, { useState } from "react";
import ReportManager from "commons/components/ReportManager";
import useResourcesByQuery from "commons/hooks/useResourcesByQuery";
import useTranslate from "commons/hooks/useTranslate";
import { IconButton, Tooltip } from "@material-ui/core";
import { CallMerge, CallSplit } from "@material-ui/icons";
import { DirectionSelect } from "./InventoryLevels";

const filters = [
  { type: "filter", name: "facilities", key: "facility_id" },
  {
    type: "filter",
    name: "products",
    key: "product_id",
    filters: { stockable: true },
  },
  {
    type: "filter",
    name: "categories",
    key: "category_id",
  },
  {
    type: "custom",
    name: "direction",
    key: "direction",
    Component: DirectionSelect,
  },
];

export const columns = [
  {
    name: "facility",
    type: "text",
  },
  {
    name: "product_name",
    type: "text",
  },
  {
    name: "product_code",
    type: "text",
  },
  {
    name: "purchase_price",
    type: "money",
  },
  {
    name: "sale_price",
    type: "money",
  },
  {
    name: "value",
    type: "money",
  },
  {
    name: "quantity",
    type: "number",
  },
  {
    name: "purchase_value",
    type: "money",
  },
  {
    name: "sale_value",
    type: "money",
  },
  {
    name: "total_value",
    type: "money",
  },
  {
    name: "last_price_profit",
    type: "money",
  },
  {
    name: "stock_value_profit",
    type: "money",
  },
];

export function useInventoryReport() {
  const [facilities] = useResourcesByQuery("facilities", true);
  const [products] = useResourcesByQuery("products", true);

  const facilitiesByID = facilities.reduce(
    (acc, curr) => ({ ...acc, [curr.id]: curr }),
    {}
  );

  const productsByID = products.reduce(
    (acc, curr) => ({ ...acc, [curr.id]: curr }),
    {}
  );

  const transformer = (records) =>
    records.map((record, index) => {
      const product = productsByID[record.product_id];
      return {
        ...record,
        value: record.total_value / record.quantity,
        id: index,
        facility: facilitiesByID[record.facility_id].name,
        product_name: product.name,
        product_code: product.code,
        sale_price: product.sale_price,
        purchase_price: product.purchase_price,
        purchase_value: product.purchase_price * record.quantity,
        sale_value: product.sale_price * record.quantity,
        last_price_profit:
          product.sale_price * record.quantity -
          product.purchase_price * record.quantity,
        stock_value_profit:
          product.sale_price * record.quantity - record.total_value,
      };
    });

  return transformer;
}

export default function InventoryValue() {
  const transformer = useInventoryReport();
  const [merge, setMerge] = useState(false);
  const { t } = useTranslate();

  return merge ? (
    <ReportManager
      key="stock-totals"
      filterByDate
      dateField="fulfilled"
      url="stock-totals"
      title="stock-value"
      filters={filters}
      columns={columns}
      recordsFunc={transformer}
      totals={[
        "total_value",
        "sale_value",
        "purchase_value",
        "last_price_profit",
        "stock_value_profit",
      ]}
      toolbarExtras={
        <Tooltip title={t("split")}>
          <IconButton onClick={() => setMerge(false)}>
            <CallSplit />
          </IconButton>
        </Tooltip>
      }
    />
  ) : (
    <ReportManager
      key="stock-levels"
      filterByDate
      dateField="fulfilled"
      url="stock-levels"
      title="stock-value"
      filters={filters}
      columns={columns}
      recordsFunc={transformer}
      totals={[
        "total_value",
        "sale_value",
        "purchase_value",
        "last_price_profit",
        "stock_value_profit",
      ]}
      toolbarExtras={
        <Tooltip title={t("merge")}>
          <IconButton onClick={() => setMerge(true)}>
            <CallMerge />
          </IconButton>
        </Tooltip>
      }
    />
  );
}
