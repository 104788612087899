import React, { useCallback, useContext, useEffect, useState } from "react";
import PageCard from "commons/components/PageCard";
import Stack from "commons/components/Stack";
import useTranslate from "commons/hooks/useTranslate";
import {
  Box,
  CircularProgress,
  debounce,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Toolbar,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { Check, ViewList } from "@material-ui/icons";
import { FrontDeskContext } from "commons/helpers/contexts";
import { useMemo } from "react";
import { prop, uniqBy } from "ramda";
import { makeStyles } from "@material-ui/styles";
import FormTextField from "commons/components/FormTextField";
import { FormMoneyField } from "commons/components/FormMoneyField";
import api from "commons/helpers/api";
import { useMutation } from "react-query";
import ErrorAlert from "commons/components/ErrorAlert";
import { FormNumberField } from "commons/components/FormNumberField";
import useQueryAllResources from "commons/hooks/useQueryAllResources";
import dayjs from "dayjs";
import usePrintTemplates from "commons/hooks/usePrintTemplates";
import { calcDiscount } from "../sales2/utils/calculators";
import { Link as RouterLink } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  highlight: {
    background: "rgba(0,0,0,0.1)",
  },
  strip: {
    background: "rgba(0,0,0,0.03)",
  },
}));

function patchProduct({ id, changes }) {
  return api.service("products").patch(id, changes);
}

function patchDiscount({ product_id, discount }) {
  if (discount.id) {
    return api
      .service("discounts")
      .update(discount.id, { ...discount, products: [product_id] });
  } else {
    return api
      .service("discounts")
      .create({ ...discount, products: [product_id] });
  }
}

function ProductRow({
  record,
  className,
  srcDiscount,
  discountCount,
  discountLabel,
}) {
  const [prices, setPrices] = useState({});
  const [discount, setDiscount] = useState({});
  const [discountedPrice, setDiscountedPrice] = useState(0);
  const { mutate, isLoading, isSuccess, error } = useMutation(patchProduct, {
    onSuccess: () => {
      //   setPrices({});
    },
  });

  const {
    mutate: mutateDiscount,
    isLoading: discountIsLoading,
    isSuccess: discountIsSuccess,
    error: discountError,
  } = useMutation(patchDiscount, {
    onSuccess: (data) => {
      console.log({ data });
      setDiscount(data);
    },
  });

  const discountUpdate = useMemo(() => {
    const handleDiscountUpdate = (discount) =>
      mutateDiscount({ product_id: record.product_id, discount });
    return debounce(handleDiscountUpdate, 1000);
  }, [mutateDiscount, record]);

  const onDiscountedPriceChange = (val) => {
    const value = Number(val);
    const sale_price = prices.sale_price ?? record.sale_price;
    const discountValue = sale_price - value;
    if (discountValue >= 0) {
      setDiscountedPrice(value);
      const newDiscount = {
        ...discount,
        value: (discountValue / 100).toString(),
      };
      setDiscount(newDiscount);
      discountUpdate(newDiscount);
    }
  };

  const onDiscountChange = useCallback(
    (discount, commit = true) => {
      const sale_price = prices.sale_price ?? record.sale_price;
      const discountValue = calcDiscount(sale_price, 1, discount);
      const discounted = sale_price - discountValue;
      if (discounted >= 0) {
        setDiscountedPrice(discounted);
        setDiscount(discount);
        if (commit) {
          discountUpdate(discount);
        }
      }
    },
    [prices.sale_price, record.sale_price, discountUpdate]
  );

  useEffect(() => {
    if (srcDiscount) {
      onDiscountChange(srcDiscount, false);
    } else {
      const discount = {
        active_from: dayjs().format("YYYY-MM-DDTHH:mm:ssZZ"),
        active_to: dayjs().add(1, "year").format("YYYY-MM-DDTHH:mm:ssZZ"),
        name: discountLabel,
        value: "0",
        min_quantity: null,
        min_price: null,
        max_value: null,
      };
      onDiscountChange(discount, false);
    }
  }, [srcDiscount, discountLabel, onDiscountChange]);

  const productUpdate = useMemo(() => {
    const handleProductUpdate = (changes) =>
      mutate({ id: record.product_id, changes });
    return debounce(handleProductUpdate, 1000);
  }, [mutate, record]);

  const onItemChange = (field) => (val) => {
    const changes = { ...prices, [field]: val };
    setPrices(changes);
    productUpdate(changes);
  };

  return (
    <>
      <ErrorAlert error={error} />
      <ErrorAlert error={discountError} />
      <TableRow className={className} hover>
        <TableCell padding="none" width="10%">
          <Box p={1}>
            <FormTextField size="small" value={record.code} disabled />
          </Box>
        </TableCell>
        <TableCell padding="none">
          <Box p={1}>
            <FormTextField size="small" value={record.name} disabled />
          </Box>
        </TableCell>
        <TableCell padding="none">
          <Box p={1}>
            <FormMoneyField
              size="small"
              value={prices.purchase_price ?? record.purchase_price}
              onChange={onItemChange("purchase_price")}
            />
          </Box>
        </TableCell>
        <TableCell padding="none">
          <Box p={1}>
            <FormMoneyField
              size="small"
              value={prices.sale_price ?? record.sale_price}
              onChange={onItemChange("sale_price")}
            />
          </Box>
        </TableCell>
        <TableCell padding="none">
          <Box p={1}>
            <FormMoneyField
              size="small"
              value={discountedPrice}
              onChange={onDiscountedPriceChange}
            />
          </Box>
        </TableCell>
        <TableCell padding="none">
          <Box p={1}>
            <FormTextField
              size="small"
              value={discount?.value}
              onChange={(value) => onDiscountChange({ ...discount, value })}
            />
          </Box>
        </TableCell>
        <TableCell>
          <Box p={1}>{discountCount && discountCount.join("|")}</Box>
        </TableCell>
        <TableCell width="5%" padding="none">
          <Box p={1}>
            {(isLoading || discountIsLoading) && <CircularProgress size={20} />}
            {(isSuccess || discountIsSuccess) && (
              <Check color="primary" size={20} />
            )}
          </Box>
        </TableCell>
      </TableRow>
    </>
  );
}

export default function FastDiscount() {
  const { t } = useTranslate();
  const { products } = useContext(FrontDeskContext);
  const [discountData] = useQueryAllResources("discounts");
  const [discountProduct] = useQueryAllResources("discount-product");
  const classes = useStyles();
  const { translate } = usePrintTemplates();

  const uniqProducts = useMemo(() => {
    return uniqBy(prop("product_id"), products);
  }, [products]);

  const discountsById = useMemo(() => {
    return discountData.reduce(
      (acc, curr) => ({
        ...acc,
        [curr.id]: curr,
      }),
      {}
    );
  }, [discountData]);

  const productDiscounts = useMemo(() => {
    return discountProduct.reduce((acc, curr) => {
      return {
        ...acc,
        [curr.product_id]: discountsById[curr.discount_id],
      };
    }, {});
  }, [discountProduct, discountsById]);

  const productDiscountCounter = useMemo(() => {
    return discountProduct.reduce((acc, curr) => {
      return {
        ...acc,
        [curr.product_id]: acc.hasOwnProperty(curr.product_id)
          ? [...acc[curr.product_id], curr.discount_id]
          : [curr.discount_id],
      };
    }, {});
  }, [discountProduct]);

  // const handlePrint = () => {};

  return (
    <PageCard>
      <Stack>
        <Toolbar disableGutters>
          <Typography style={{ flex: "1 1 100%" }} variant="h4">
            {t("fast-discount")}
          </Typography>
          <Tooltip title={t("list")}>
            <IconButton component={RouterLink} to={`/s/products`}>
              <ViewList />
            </IconButton>
          </Tooltip>
          {/* <IconButton onClick={handlePrint}>
            <Print />
          </IconButton> */}
        </Toolbar>
        {/* <JSONInspector data={productDiscounts} /> */}
        <PaginatedView
          data={uniqProducts}
          id="product_id"
          searchFields={["code", "name"]}
          Header={
            <TableRow>
              <TableCell>{t("code")}</TableCell>
              <TableCell>{t("product")}</TableCell>
              <TableCell>{t("purchase_price")}</TableCell>
              <TableCell>{t("sale_price")}</TableCell>
              <TableCell>{t("discount_price")}</TableCell>
              <TableCell>{t("discount_amount")}</TableCell>
              <TableCell>ID</TableCell>
              <TableCell></TableCell>
            </TableRow>
          }
          rowRenderer={(record, index) => (
            <ProductRow
              key={record.product_id}
              className={index % 2 ? classes.strip : ""}
              record={record}
              srcDiscount={productDiscounts[record.product_id]}
              discountCount={productDiscountCounter[record.product_id]}
              discountLabel={translate(1, "labels", 27, t("discount"))}
            />
          )}
        />
      </Stack>
    </PageCard>
  );
}

function PaginatedView({ data = [], Header, rowRenderer, searchFields = [] }) {
  const { t } = useTranslate();
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState({});
  const classes = useStyles();

  const onSearchChange = (field) => (e) => {
    const val = e.target.value;
    setSearch((old) => ({
      ...old,
      [field]: val !== "" ? val : null,
    }));
    setPage(1);
  };

  const filteredData = useMemo(() => {
    return data.filter((record) => {
      return Object.keys(search).every((key) => {
        const query = search[key];
        return !query || record[key].includes(query);
      });
    });
  }, [data, search]);

  const max = Math.ceil(filteredData.length / rowsPerPage);

  return (
    <div style={{ padding: "1em" }}>
      <TableContainer>
        <Table>
          <TableHead className={classes.highlight}>{Header}</TableHead>

          <TableBody>
            {searchFields.length > 1 && (
              <TableRow>
                {searchFields.map((field) => (
                  <TableCell padding="none" key={field}>
                    <Box p={1}>
                      <TextField
                        variant="outlined"
                        size="small"
                        value={search[field] || ""}
                        onChange={onSearchChange(field)}
                      />
                    </Box>
                  </TableCell>
                ))}
              </TableRow>
            )}
            {filteredData
              .slice(
                (page - 1) * rowsPerPage,
                (page - 1) * rowsPerPage + rowsPerPage
              )
              .map(rowRenderer)}
          </TableBody>
        </Table>
      </TableContainer>
      <br />
      <div className={classes.highlight}>
        <Grid container spacing={2}>
          <Grid item sm={2} />
          <FormNumberField
            grid={2}
            label={t("items_count")}
            size="small"
            value={filteredData.length}
            disabled
          />
          <FormNumberField
            grid={2}
            label={t("rowsPerPage")}
            size="small"
            value={rowsPerPage}
            onChange={(e) => {
              const val = parseInt(e, 10);
              if (val <= filteredData.length) {
                setRowsPerPage(val);
              }
            }}
            step={5}
          />
          <FormNumberField
            grid={2}
            label={t("page")}
            size="small"
            value={page}
            onChange={(e) => {
              const val = parseInt(e, 10);
              if (!isNaN(val) && val <= max && val > 0) {
                setPage(val);
              }
            }}
          />
          <FormNumberField
            grid={2}
            label={t("page_count")}
            size="small"
            value={max}
            disabled
          />
          <Grid item sm={2} />
        </Grid>
      </div>
    </div>
  );
}
