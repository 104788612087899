import React from "react";
import useControlledResourcePage from "commons/hooks/useControlledResourcePage";
import ResourceSinglePage from "commons/components/ResourceSinglePage";
import Editor from "./Editor";

const base = "products";
const initialModel = {
  active: true,
  stockable: false,
  purchasable: true,
  saleable: true,
  type: "ONCE",
  image: "service.png",
};

export default function Single({ extras = [] }) {
  const { current, send, updateModel } = useControlledResourcePage(
    base,
    initialModel,
    true,
    "services"
  );
  return (
    <ResourceSinglePage
      title="services"
      route="services"
      current={current}
      send={send}
      accessGroup
    >
      <Editor
        current={current}
        send={send}
        updateModel={updateModel}
        extras={extras}
      />
    </ResourceSinglePage>
  );
}
