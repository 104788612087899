import React from "react";
import ResourceListPage from "commons/components/ResourceListPage";

export const columns = [
  {
    name: "reference",
    type: "text",
    sortable: true,
    searchable: true,
  },
  {
    name: "date",
    type: "datetime",
    sortable: true,
    searchable: true,
  },
  {
    name: "supplier",
    type: "text",
    sortable: true,
    searchable: true,
    searchKey: "suppliers.name",
  },
  {
    name: "facility",
    type: "text",
    sortable: true,
    searchable: true,
    searchKey: "facilities.name",
  },
  {
    name: "user",
    type: "text",
    sortable: true,
    searchable: true,
    searchKey: "users.username",
  },
  {
    name: "closed",
    type: "boolean",
    sortable: true,
    searchable: true,
  },
  {
    name: "summary",
    type: "text",
    sortable: true,
    searchable: true,
  },

  {
    name: "subtotal",
    type: "money",
    sortable: true,
    searchable: true,
  },
  {
    name: "discount",
    type: "money",
    sortable: true,
    searchable: true,
  },
  {
    name: "tax",
    type: "money",
    sortable: true,
    searchable: true,
  },
  {
    name: "total",
    type: "money",
    sortable: true,
    searchable: true,
  },
  {
    name: "commission",
    type: "money",
    sortable: true,
    searchable: true,
  },
  {
    name: "cost",
    type: "money",
    sortable: true,
    searchable: true,
  },
  {
    name: "notes",
    type: "text",
    sortable: true,
    searchable: true,
  },
];

export default function List() {
  return <ResourceListPage url="purchases" columns={columns} />;
}
