import React from "react";
import CustomersReport from "./CustomersReport";

export default function SuppliersReport() {
  return (
    <CustomersReport
      title="suppliersReport"
      base="suppliers"
      name="supplier"
      field="supplier_id"
      operationService="purchases"
      paymentService="supplier-payments"
    />
  );
}
