import React from "react";
import useControlledResourcePage from "commons/hooks/useControlledResourcePage";
import ResourceSinglePage from "commons/components/ResourceSinglePage";
import useResourcesByQuery from "commons/hooks/useResourcesByQuery";
import FormSelectField from "commons/components/FormSelectField";
import { FormDateTimeField } from "commons/components/FormDateField";

const base = "sessions";

export default function Single() {
  const {
    current,
    send,
    updateModel,
    model,
    rules,
  } = useControlledResourcePage(base);
  const [locations] = useResourcesByQuery("service-locations", true);
  const [products] = useResourcesByQuery("products", true, {
    type: {
      $in: ["SESSIONS"],
    },
  });

  return (
    <ResourceSinglePage title={base} current={current} send={send} accessGroup>
      <FormSelectField
        grid={3}
        options={products}
        label="service"
        value={model.product_id}
        onChange={updateModel("product_id")}
        error={rules.product_id}
      />
      <FormSelectField
        grid={3}
        options={locations}
        label="service_location"
        value={model.service_location_id}
        onChange={updateModel("service_location_id")}
        error={rules.service_location_id}
      />
      <FormDateTimeField
        grid={3}
        label="starts"
        value={model.starts}
        onChange={updateModel("starts")}
        error={rules.starts}
      />
      <FormDateTimeField
        grid={3}
        label="ends"
        value={model.ends}
        onChange={updateModel("ends")}
        error={rules.ends}
      />
    </ResourceSinglePage>
  );
}
