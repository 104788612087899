import React, { useState, useMemo } from "react";
import PageCard from "commons/components/PageCard";
import LoadingIndicator from "commons/components/LoadingIndicator";
import ErrorAlert from "commons/components/ErrorAlert";
import Stack from "commons/components/Stack";
import useTranslate from "commons/hooks/useTranslate";
import {
  Grid,
  Tooltip,
  IconButton,
  Typography,
  Toolbar,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  makeStyles,
  TableBody,
  Checkbox,
  TextField,
  Portal,
  Zoom,
  Fab,
  Box,
} from "@material-ui/core";
import { Print, Save } from "@material-ui/icons";
import { ExcelExporterButton } from "commons/components/ResourceListPage";
import FormSelectField from "commons/components/FormSelectField";
import api from "commons/helpers/api";
import DataCard from "commons/components/DataCard";
import useSessionsManager from "commons/pages/sessions/useSessionsManager";
import CardSection from "commons/components/CardSection";

const useStyles = makeStyles((theme) => ({
  highlight: {
    background: "rgba(0,0,0,0.1)",
  },
  strip: {
    background: "rgba(0,0,0,0.03)",
  },
}));

export default function Manager() {
  const { t } = useTranslate();
  const [facility, setFacility] = useState(null);
  const [service, setService] = useState(null);
  const {
    facilities,
    services,
    customers,
    sessions,
    attendances,
  } = useSessionsManager(facility, service);

  const [changes, setChanges] = useState({});

  const [session, setSession] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const classes = useStyles();

  const sessionAttendances = useMemo(() => {
    return attendances.filter((att) => att.session_id === session);
  }, [attendances, session]);

  const attendanceByStudent = useMemo(() => {
    return sessionAttendances.reduce(
      (acc, curr) => ({ ...acc, [curr.customer_id]: curr }),
      {}
    );
  }, [sessionAttendances]);

  const all = useMemo(() => ({ ...attendanceByStudent, ...changes }), [
    attendanceByStudent,
    changes,
  ]);

  const updateAttendance = (customer_id, field, value) => {
    const rec = all[customer_id];
    if (rec) {
      setChanges((old) => ({
        ...old,
        [customer_id]: {
          ...rec,
          [field]: value,
        },
      }));
    } else {
      setChanges((old) => ({
        ...old,
        [customer_id]: {
          customer_id,
          session_id: session,
          notes: "",
          [field]: value,
        },
      }));
    }
  };

  const onSave = () => {
    setLoading(true);
    const records = Object.values(changes);
    const creates = records.filter((rec) => !Boolean(rec.id));
    const updates = records.filter((rec) => Boolean(rec.id));
    const createOp = api.service("session-attendances").create(creates);
    const updateOps = updates.map((rec) =>
      api.service("session-attendances").update(rec.id, rec)
    );
    Promise.all([createOp, ...updateOps])
      .then(() => {
        setChanges({});
      })
      .catch(setError)
      .finally((_) => setLoading(false));
  };
  const present_count = useMemo(
    () => attendances.filter((rec) => rec.status === "PRESENT").length,
    [attendances]
  );

  const absent_count = useMemo(
    () => attendances.filter((rec) => rec.status === "ABSENT").length,
    [attendances]
  );

  const attendance_percentage = useMemo(() => {
    const val = present_count / (customers.length * sessions.length);
    return isNaN(val) ? 0 : (val * 100).toFixed(2);
  }, [customers, sessions, present_count]);

  const absence_percentage = useMemo(() => {
    const val = absent_count / (customers.length * sessions.length);
    return isNaN(val) ? 0 : (val * 100).toFixed(2);
  }, [customers, sessions, absent_count]);

  const uncounted_percentage = useMemo(() => {
    const total = customers.length * sessions.length;
    const allCount = attendances.length;
    const val = (total - allCount) / total;
    return isNaN(val) ? 0 : (val * 100).toFixed(2);
  }, [customers, sessions, attendances]);

  return (
    <PageCard>
      <LoadingIndicator show={loading} />
      <ErrorAlert error={error} />
      <Stack>
        <SimpleResourceToolbar label="session-attendances" />
        <Grid container spacing={2}>
          <FormSelectField
            grid={4}
            label="facility"
            options={facilities}
            value={facility}
            onChange={setFacility}
          />
          <FormSelectField
            grid={4}
            label="service"
            options={services}
            optionKey="product_id"
            value={service}
            onChange={setService}
          />
          <FormSelectField
            grid={4}
            label="session"
            options={sessions}
            optionLabel="starts"
            value={session}
            onChange={setSession}
          />
        </Grid>
        <CardSection>
          <Grid container spacing={1}>
            <DataCard title={t("customers")} value={customers.length} />
            <DataCard title={t("sessions")} value={sessions.length} />
            <DataCard
              title={t("attendance_percentage")}
              value={`${attendance_percentage}%`}
            />
            <DataCard
              title={t("absence_percentage")}
              value={`${absence_percentage}%`}
            />
            <DataCard
              title={t("uncounted_percentage")}
              value={`${uncounted_percentage}%`}
            />
          </Grid>
        </CardSection>
        {customers.length > 0 && session && (
          <TableContainer>
            <Table>
              <TableHead className={classes.highlight}>
                <TableRow>
                  <TableCell>{t("customer")}</TableCell>
                  <TableCell>{t("PRESENT")}</TableCell>
                  <TableCell>{t("ABSENT")}</TableCell>
                  <TableCell>{t("notes")}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {customers.map(({ customer_id, customer }, index) => {
                  const record = all[customer_id] || false;
                  return (
                    <TableRow
                      key={customer_id}
                      className={index % 2 ? classes.strip : ""}
                      hover
                    >
                      <TableCell>{customer}</TableCell>
                      <TableCell padding="none">
                        <Checkbox
                          checked={record && record.status === "PRESENT"}
                          onChange={() =>
                            updateAttendance(customer_id, "status", "PRESENT")
                          }
                        />
                      </TableCell>
                      <TableCell padding="none">
                        <Checkbox
                          checked={record && record.status === "ABSENT"}
                          onChange={() =>
                            updateAttendance(customer_id, "status", "ABSENT")
                          }
                        />
                      </TableCell>
                      <TableCell padding="none">
                        {record && (
                          <TextField
                            size="small"
                            value={record.notes}
                            onChange={(e) =>
                              updateAttendance(
                                customer_id,
                                "notes",
                                e.target.value
                              )
                            }
                          />
                        )}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Stack>
      <Portal>
        <Box position="fixed" bottom={24} right={24}>
          <Zoom in style={{ transitionDelay: "250ms" }}>
            <Fab
              color="primary"
              onClick={onSave}
              disabled={Object.keys(changes).length === 0}
            >
              <Save />
            </Fab>
          </Zoom>
        </Box>
      </Portal>
    </PageCard>
  );
}

export function SimpleResourceToolbar({ label }) {
  const { t } = useTranslate();

  return (
    <Toolbar disableGutters>
      <Typography style={{ flex: "1 1 100%" }} variant="h4">
        {t(label)}
      </Typography>
      <ExcelExporterButton data={[]} filename={t(label)} />
      <Tooltip title={t("print")}>
        <IconButton>
          <Print />
        </IconButton>
      </Tooltip>
    </Toolbar>
  );
}
