import React from "react";
import PageCard from "commons/components/PageCard";
import ResourceToolbar from "commons/components/ResourceToolbar";
import ResourceEditor from "commons/components/ResourceEditor";
import FormTextField from "commons/components/FormTextField";
import { FormDateField } from "commons/components/FormDateField";
import LinkTabs from "commons/components/LinkTabs";
import useControlledResourcePage from "commons/hooks/useControlledResourcePage";
import { Products } from "../employees/Controller";
import {
  useBalance,
  TotalsGroup,
  TabbedSection,
  PaymentsBalance,
  CommissionsBalance,
} from "commons/components/Balance";
import Stack from "commons/components/Stack";

const base = "contractors";

export default function Standalone() {
  const { current, send, model, updateModel, id } = useControlledResourcePage(
    base
  );
  const hasResource = Boolean(model.id);

  return (
    <PageCard>
      <ResourceToolbar title={base} current={current} send={send} accessGroup />
      <LinkTabs
        show={hasResource}
        tabs={[
          {
            name: "editor",
            path: `/s/contractors/${id}`,
            component: (
              <Editor current={current} send={send} updateModel={updateModel} />
            ),
          },
          {
            name: "balance",
            path: `/s/contractors/${id}/balance`,
            component: <Balance id={id} type="employees" />,
          },
          {
            name: "products",
            path: `/s/contractors/${id}/products`,
            component: <Products id={id} />,
          },
          {
            name: "services",
            path: `/s/contractors/${id}/services`,
            component: <Products services id={id} />,
          },
        ]}
      />
    </PageCard>
  );
}

export function Editor({ current, send, updateModel, ...props }) {
  const { model, rules } = current.context;

  return (
    <ResourceEditor current={current} send={send} {...props}>
      <FormTextField
        label="name"
        value={model.name}
        onChange={updateModel("name")}
        error={rules.name}
      />
      <FormTextField
        grid={6}
        label="address"
        value={model.address}
        onChange={updateModel("address")}
        error={rules.address}
      />
      <FormTextField
        grid={3}
        label="sale_commission"
        value={model.sale_commission}
        onChange={updateModel("sale_commission")}
        error={rules.sale_commission}
      />
      <FormTextField
        grid={3}
        label="primary_phone"
        value={model.primary_phone}
        onChange={updateModel("primary_phone")}
        error={rules.primary_phone}
      />
      <FormTextField
        grid={3}
        label="secondary_phone"
        value={model.secondary_phone}
        onChange={updateModel("secondary_phone")}
        error={rules.secondary_phone}
      />
      <FormTextField
        grid={3}
        label="email"
        value={model.email}
        onChange={updateModel("email")}
        error={rules.email}
      />
      <FormTextField
        grid={3}
        label="nid"
        value={model.nid}
        onChange={updateModel("nid")}
        error={rules.nid}
      />
      <FormDateField
        grid={3}
        label="dob"
        value={model.dob}
        onChange={updateModel("dob")}
        error={rules.dob}
      />
      <FormTextField
        multiline
        label="notes"
        value={model.notes}
        onChange={updateModel("notes")}
        error={rules.notes}
      />
    </ResourceEditor>
  );
}

function Balance({ id }) {
  const { balance } = useBalance(id, "balance", "employees", [
    "sales",
    "purchases",
    "employee-payments",
  ]);

  return (
    <Stack>
      <TotalsGroup
        labels={["sales", "purchases", "payments", "refunds", "balance"]}
        values={balance}
      />
      <TabbedSection
        labels={["sales", "purchases", "payments", "refunds"]}
        sections={[
          <CommissionsBalance
            url="employee-sale"
            filter={{ employee_id: id }}
          />,
          <CommissionsBalance
            url="employee-purchase"
            filter={{ employee_id: id }}
          />,
          <PaymentsBalance
            id={id}
            url="employee-payments"
            field="employee_id"
          />,
          <PaymentsBalance
            id={id}
            url="employee-payments"
            field="employee_id"
            refund
          />,
        ]}
      />
    </Stack>
  );
}
