import React, { useMemo } from "react";
import {
  ReportViewer,
  useReportQuery,
  useReportController,
} from "commons/components/ReportManager";
import { sumField, sumObj } from "commons/helpers/utils";
import { groupBy, omit, prop } from "ramda";
import useQueryAllResources from "commons/hooks/useQueryAllResources";

const filters = [
  {
    type: "filter",
    name: "access-groups",
    key: "user_group_permission.access_group_id",
  },
  // {
  //   type: "filter",
  //   name: "currencies",
  //   key: "currency_id",
  // },
];

// const columns = [
//   {
//     name: "line",
//     type: "translate",
//   },
//   {
//     name: "amount",
//     type: "balance",
//   },
// ];

export default function FinanceOverview() {
  const [currencies] = useQueryAllResources("currencies");
  const columns = useMemo(() => {
    const lookup = currencies.reduce((a, c) => {
      return {
        ...a,
        [c.id]: c.name,
      };
    }, {});
    return [
      {
        name: "line",
        type: "translate",
      },
      ...currencies.map((c) => ({
        name: c.id,
        type: "balance",
        title: lookup[c.id],
      })),
    ];
  }, [currencies]);

  const [serverQuery, query, duration, setQuery, setDuration] =
    useReportQuery();
  const [sales, salesError, salesIsLoading, salesApply] = useReportController(
    "sales",
    duration,
    "date"
  );
  const [stocks, stocksError, stocksIsLoading, stocksApply] =
    useReportController("stocks", duration, "fulfilled");
  const [purchases, purchasesError, purchasesIsLoading, purchasesApply] =
    useReportController("purchases", duration, "date");
  const [salaries, salariesError, salariesIsLoading, salariesApply] =
    useReportController("salaries", duration, "due_date");
  const [
    saleCommissions,
    saleCommissionsError,
    saleCommissionsIsLoading,
    saleCommissionsApply,
  ] = useReportController("employee-sale", duration, "date");
  const [
    purchaseCommissions,
    purchaseCommissionsError,
    purchaseCommissionsIsLoading,
    purchaseCommissionsApply,
  ] = useReportController("employee-purchase", duration, "date");
  const [customers, customersError, customersIsLoading, customersApply] =
    useReportController("customer-payments", duration, "date");
  const [suppliers, suppliersError, suppliersIsLoading, suppliersApply] =
    useReportController("supplier-payments", duration, "date");
  const [employees, employeesError, employeesIsLoading, employeesApply] =
    useReportController("employee-payments", duration, "date");

  const apply = () => {
    salesApply(serverQuery);
    stocksApply(serverQuery);
    purchasesApply(serverQuery);
    salariesApply(serverQuery);
    saleCommissionsApply(serverQuery);
    purchaseCommissionsApply(serverQuery);
    customersApply(serverQuery);
    suppliersApply(serverQuery);
    employeesApply(serverQuery);
  };

  const records = useMemo(() => {
    const salesTotal = sumObj("total", groupBy(prop("currency_id"), sales));
    const purchasesTotal = sumObj(
      "total",
      groupBy(prop("currency_id"), purchases)
    );
    const commissions = [...saleCommissions, ...purchaseCommissions];
    const commissionsTotal = sumObj(
      "applied_value",
      groupBy(prop("currency_id"), commissions)
    );

    const stocksTotal = sumField("total_value")(stocks);
    const salariesTotal = sumField("net_salary")(salaries);
    // const salesTotal = sumField("total")(sales);
    // const purchasesTotal = sumField("total")(purchases);
    // const commissionsTotal = sumField("applied_value")(commissions);
    const first = [
      { line: "sales_total", ...salesTotal },
      { line: "stocks_total", 1: stocksTotal },
      { line: "purchases_total", ...purchasesTotal },
      { line: "salaries_total", 1: salariesTotal },
      { line: "commissions_total", ...commissionsTotal },
    ];
    const firstByType = first.reduce((acc, l) => {
      return { ...acc, [l.line]: omit(["line"], l) };
    }, {});
    const profit = currencies.reduce(
      (acc, curr) => {
        const id = curr.id;
        const salesTotal = firstByType["sales_total"][id] || 0;
        const stocksTotal = firstByType["stocks_total"][id] || 0;
        const purchasesTotal = firstByType["purchases_total"][id] || 0;
        const salariesTotal = firstByType["salaries_total"][id] || 0;
        const commissionsTotal = firstByType["commissions_total"][id] || 0;
        return {
          ...acc,
          [id]:
            salesTotal +
            stocksTotal -
            purchasesTotal -
            salariesTotal -
            commissionsTotal,
        };
      },
      { line: "profit" }
    );
    const customerPayments = sumObj(
      "amount_in_currency",
      groupBy(prop("currency_id"), customers)
    );
    const supplierPayments = sumObj(
      "amount_in_currency",
      groupBy(prop("currency_id"), suppliers)
    );
    const employeePayments = sumObj(
      "amount_in_currency",
      groupBy(prop("currency_id"), employees)
    );
    const second = [
      { line: "customer_payments", ...customerPayments },
      { line: "supplier_payments", ...supplierPayments },
      { line: "employee_payments", ...employeePayments },
    ];
    const secondByType = second.reduce((acc, l) => {
      return { ...acc, [l.line]: omit(["line"], l) };
    }, {});

    const payments_difference = currencies.reduce(
      (acc, curr) => {
        const id = curr.id;
        const customerPayments = secondByType["customer_payments"][id] || 0;
        const supplierPayments = secondByType["supplier_payments"][id] || 0;
        const employeePayments = secondByType["employee_payments"][id] || 0;
        return {
          ...acc,
          [id]: customerPayments - supplierPayments - employeePayments,
        };
      },
      { line: "payments_difference" }
    );

    return [
      [
        ...first,
        profit,

        // { line: "sales_total", amount: salesTotal },
        // { line: "purchases_total", amount: purchasesTotal },
        // { line: "salaries_total", amount: salariesTotal },
        // { line: "commissions_total", amount: commissionsTotal },
        // {
        //   line: "profit",
        //   amount:
        //     salesTotal +
        //     stocksTotal -
        //     purchasesTotal +
        //     salariesTotal -
        //     commissionsTotal,
        // },
      ],
      [
        ...second,
        payments_difference,
        // { line: "customer_payments", amount: customerPayments },
        // { line: "supplier_payments", amount: supplierPayments },
        // { line: "employee_payments", amount: employeePayments },
        // {
        //   line: "payments_difference",
        //   amount: customerPayments - supplierPayments - employeePayments,
        // },
      ],
    ];
  }, [
    sales,
    stocks,
    purchases,
    salaries,
    saleCommissions,
    purchaseCommissions,
    customers,
    suppliers,
    employees,
    currencies,
  ]);

  const errors = [
    salesError,
    stocksError,
    purchasesError,
    salariesError,
    saleCommissionsError,
    purchaseCommissionsError,
    customersError,
    suppliersError,
    employeesError,
  ].filter((er) => Boolean(er));
  const isLoading = [
    salesIsLoading,
    stocksIsLoading,
    purchasesIsLoading,
    salariesIsLoading,
    saleCommissionsIsLoading,
    purchaseCommissionsIsLoading,
    customersIsLoading,
    suppliersIsLoading,
    employeesIsLoading,
  ].every((l) => l);

  return (
    <ReportViewer
      title="financeOverview"
      filterByDate
      filters={filters}
      columns={columns}
      records={records}
      error={errors.length > 0 ? errors[0] : null}
      isLoading={isLoading}
      duration={duration}
      query={query}
      setQuery={setQuery}
      setDuration={setDuration}
      hideIndex
      onApply={apply}
    />
  );
}
