import React from "react";
import ReportManager, {
  extractCurrencyName,
  groupByCurrency,
  totalsColumns,
  totalsTransformer,
} from "commons/components/ReportManager";
import { compose, map } from "ramda";

const filters = [
  { type: "filter", name: "facilities", key: "facility_id" },
  {
    type: "filter",
    name: "users",
    key: "user_id",
    extras: { optionLabel: "username" },
  },
  { type: "filter", name: "suppliers", key: "supplier_id" },
];

const columns = [
  {
    name: "supplier",
    type: "text",
  },
  ...totalsColumns,
];

const bySupplierID = (record) => record.supplier_id + "";

export default function PurchasesBySupplier() {
  return (
    <ReportManager
      url="purchases"
      title="purchasesBySupplier"
      filterByDate
      filters={filters}
      columns={columns}
      totals={[
        "number_of_operations",
        "subtotal",
        "discount",
        "tax",
        "total",
        "cost",
      ]}
      recordsFunc={compose(
        map(totalsTransformer(bySupplierID, "supplier_id")),
        groupByCurrency
      )}
      titlesFunc={extractCurrencyName}
    />
  );
}
