import React, { useState, useMemo } from "react";
import PageCard from "commons/components/PageCard";
import LoadingIndicator from "commons/components/LoadingIndicator";
import ErrorAlert from "commons/components/ErrorAlert";
import Stack from "commons/components/Stack";
import {
  DurationFilter,
  ReportsToolbar,
} from "commons/components/ReportManager";
import useResourcesByQuery from "commons/hooks/useResourcesByQuery";
import {
  Grid,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Typography,
  TableHead,
} from "@material-ui/core";
import FormSelectField from "commons/components/FormSelectField";
import { slice, takeLastWhile } from "ramda";
import { useAccountsReports } from "./AccountsBalance";
import { sumField } from "commons/helpers/utils";
import useTranslate from "commons/hooks/useTranslate";
import dayjs from "dayjs";
import {
  PrintTemplate,
  usePrintManager,
} from "commons/components/PrintManager";

const updateWithAccountBalance = (section = [], accountsTree = []) => {
  return section.map((item) => {
    if (item.type === "ACCOUNT") {
      const account = accountsTree.find(
        (acc) => Number(acc.id) === Number(item.value)
      );
      if (account) {
        return {
          ...item,
          label: account.name,
          debit: account.debit_total,
          credit: account.credit_total,
        };
      }
    }
    // is a label
    return { ...item, label: item.value };
  });
};

const isAccount = (acc) => acc.type === "ACCOUNT";

const updateWithSum = (both = [], i) => {
  const data = both[i];
  return data.map((item, index) => {
    if (item.type === "SUM_PREVIOUS") {
      const previous = slice(0, index, data).filter(isAccount);
      return {
        ...item,
        debit: sumField("debit")(previous),
        credit: sumField("credit")(previous),
      };
    }
    if (item.type === "SUM_SECTION") {
      const previous = slice(0, index, data);
      const section = takeLastWhile(isAccount, previous);
      return {
        ...item,
        debit: sumField("debit")(section),
        credit: sumField("credit")(section),
      };
    }
    if (item.type === "BALANCE") {
      const fst = slice(0, index, both[0]).filter(isAccount);
      const snd = slice(0, index, both[1]).filter(isAccount);
      const all = [...fst, ...snd];
      return {
        ...item,
        debit: sumField("debit")(all),
        credit: sumField("credit")(all),
      };
    }
    return item;
  });
};

export default function AccountsBalance() {
  const [duration, setDuration] = useState({
    start: dayjs().startOf("YEAR"),
    end: dayjs().endOf("YEAR"),
  });
  const { records, error, isLoading, accountsTree } = useAccountsReports(
    duration
  );
  const [templates] = useResourcesByQuery("accounting-reports", true);
  const [template, setTemplate] = useState(null);
  const { active, onPrintRequest, onPrintCompleted } = usePrintManager();

  const fullTemplate = useMemo(() => {
    if (template === null) return null;
    const [fst, snd] = template.body ? JSON.parse(template.body) : [[], []];
    const accountsUpdated = [
      updateWithAccountBalance(fst, accountsTree),
      updateWithAccountBalance(snd, accountsTree),
    ];
    const sumUpdates = [
      updateWithSum(accountsUpdated, 0),
      updateWithSum(accountsUpdated, 1),
    ];
    return {
      ...template,
      body: sumUpdates,
    };
  }, [template, accountsTree]);

  return (
    <PageCard>
      <LoadingIndicator show={isLoading} />
      <ErrorAlert error={error} />
      <Stack>
        <ReportsToolbar
          columns={[]}
          title="accountingReports"
          records={records}
          onPrintRequest={onPrintRequest}
        />
        <DurationFilter
          duration={duration}
          onChange={setDuration}
          initialType="YEAR"
        />
        <PrintTemplate active={active} onPrintCompleted={onPrintCompleted}>
          <Grid container>
            <FormSelectField
              label="template"
              options={templates}
              value={template ? template.id : null}
              onChange={(val) => {
                const temp = templates.find((template) => template.id === val);
                if (temp) {
                  setTemplate(temp);
                }
              }}
            />
          </Grid>
          {fullTemplate &&
            (fullTemplate.type !== "four-columns" ? (
              <SectionViewer index={0} template={fullTemplate} />
            ) : (
              <Grid container spacing={2}>
                <Grid item xs sm={6}>
                  <SectionViewer index={0} template={fullTemplate} />
                </Grid>
                <Grid item xs sm={6}>
                  <SectionViewer index={1} template={fullTemplate} />
                </Grid>
              </Grid>
            ))}
        </PrintTemplate>
      </Stack>
    </PageCard>
  );
}

const getItemValue = (item) => {
  if (item.type === "LABEL") return "";
  const diff = (item.debit - item.credit) / 100;
  return diff < 0
    ? `(${Math.abs(diff).toLocaleString()})`
    : diff.toLocaleString();
};

function SectionViewer({ template, index }) {
  const { t } = useTranslate();
  const data = template.body[index] || [];
  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>{t("account")}</TableCell>
            <TableCell>
              {template.type === "three-columns" ? t("debit") : t("balance")}
            </TableCell>
            {template.type === "three-columns" && (
              <TableCell>{t("credit")}</TableCell>
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((item) => (
            <TableRow key={item.id}>
              <TableCell>
                <Typography variant="body1" noWrap>
                  {item.type === "ACCOUNT" ? (
                    item.label
                  ) : (
                    <strong>{item.label}</strong>
                  )}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="body1" noWrap>
                  {getItemValue(item)}
                </Typography>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
