import React from "react";
import ResourceListPage from "commons/components/ResourceListPage";

const columns = [
  {
    name: "name",
    type: "text",
    sortable: true,
    searchable: true,
  },
  {
    name: "type",
    type: "translate",
    sortable: true,
    searchable: true,
  },
];

export default function List() {
  return (
    <ResourceListPage
      url="accounting-reports"
      title="reports/accounting-reports-templates"
      columns={columns}
    />
  );
}
