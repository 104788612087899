import React from "react";
import useTranslate from "commons/hooks/useTranslate";
import { adjust, map, propEq, reject, update } from "ramda";
import {
  Grid,
  Tooltip,
  IconButton,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  TableHead,
} from "@material-ui/core";
import { Delete, ArrowDownward, ArrowUpward, Add } from "@material-ui/icons";
import CardSection from "commons/components/CardSection";
import { Alert } from "@material-ui/lab";
import FormSelectField from "commons/components/FormSelectField";
import useResourcesByQuery from "commons/hooks/useResourcesByQuery";
import FormTextField from "commons/components/FormTextField";

const CellTypes = [
  "ACCOUNT",
  "LABEL",
  "SUM_SECTION",
  "SUM_PREVIOUS",
  "BALANCE",
];

export default function FormBuilder({ value = [], onChange, error, type }) {
  const { t } = useTranslate();
  const [accounts] = useResourcesByQuery("accounts", true);

  const switchItemsByIndex = (fst, snd, value) => {
    const item = value[fst];
    const replaced = value[snd];
    const newValue = value.map((original, i) => {
      if (i === fst) return replaced;
      if (i === snd) return item;
      return original;
    });
    return newValue;
  };

  const onMoveUp = (i) => (item) => {
    const index = value[i].findIndex((i) => i.id === item.id);
    if (index - 1 < 0) return;
    onChange(update(i, switchItemsByIndex(index, index - 1, value[i]), value));
  };

  const onMoveDown = (i) => (item) => {
    const index = value[i].findIndex((i) => i.id === item.id);
    if (index + 1 >= value[i].length) return;
    onChange(update(i, switchItemsByIndex(index, index + 1, value[i]), value));
  };

  const addRow = (index = 0) => {
    const data = value[index] || [];
    const item = {
      id: Date.now(),
      type: "", // ACCOUNT, LABEL, SUM_SECTION, SUM_ALL, DIFFERENCE
      value: null,
    };
    let changed = value;
    changed[index] = [...data, item];
    onChange(changed);
  };

  const onRemove = (index) => (item) =>
    onChange(adjust(index, reject(propEq("id", item.id)), value));

  const onItemChange = (index) => (item) =>
    onChange(
      adjust(
        index,
        map((i) => (i.id === item.id ? item : i)),
        value
      )
    );

  return (
    <Grid item xs={12}>
      {error && <Alert severity="error">{t("rules." + error)}</Alert>}
      <CardSection>
        {type !== "four-columns" ? (
          <SectionController
            type={type}
            data={value[0]}
            accounts={accounts}
            onAddRow={() => addRow(0)}
            onRemove={onRemove(0)}
            onItemChange={onItemChange(0)}
            onMoveUp={onMoveUp(0)}
            onMoveDown={onMoveDown(0)}
          />
        ) : (
          <Grid container spacing={2}>
            <Grid item xs sm={6}>
              <SectionController
                type={type}
                data={value[0]}
                accounts={accounts}
                onAddRow={() => addRow(0)}
                onRemove={onRemove(0)}
                onItemChange={onItemChange(0)}
                onMoveUp={onMoveUp(0)}
                onMoveDown={onMoveDown(0)}
              />
            </Grid>
            <Grid item xs sm={6}>
              <SectionController
                type={type}
                data={value[1]}
                accounts={accounts}
                onAddRow={() => addRow(1)}
                onRemove={onRemove(1)}
                onItemChange={onItemChange(1)}
                onMoveUp={onMoveUp(1)}
                onMoveDown={onMoveDown(1)}
              />
            </Grid>
          </Grid>
        )}
      </CardSection>
    </Grid>
  );
}

function SectionController({
  type,
  data = [],
  onAddRow,
  onItemChange,
  accounts,
  onRemove,
  onMoveUp,
  onMoveDown,
}) {
  const { t } = useTranslate();
  return (
    <>
      <div>
        <Tooltip title={t("addRow")}>
          <IconButton color="secondary" onClick={onAddRow}>
            <Add />
          </IconButton>
        </Tooltip>
      </div>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>{t("account")}</TableCell>
              <TableCell>
                {type === "three-columns" ? t("debit") : t("balance")}
              </TableCell>
              {type === "three-columns" && <TableCell>{t("credit")}</TableCell>}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((item) => {
              return (
                <TableRow key={item.id}>
                  <TableCell style={{ maxWidth: "50%" }}>
                    <CellController
                      item={item}
                      onItemChange={onItemChange}
                      accounts={accounts}
                      onRemove={onRemove}
                      onMoveUp={onMoveUp}
                      onMoveDown={onMoveDown}
                    />
                  </TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}

function CellController({
  item,
  onItemChange,
  accounts,
  onRemove,
  onMoveUp,
  onMoveDown,
}) {
  const { t } = useTranslate();
  return (
    <Grid container spacing={1} style={{ maxWidth: "500px" }}>
      <Grid item xs="auto">
        <IconButton size="small" onClick={() => onRemove(item)}>
          <Delete />
        </IconButton>
        <IconButton size="small" onClick={() => onMoveUp(item)}>
          <ArrowUpward />
        </IconButton>
        <IconButton size="small" onClick={() => onMoveDown(item)}>
          <ArrowDownward />
        </IconButton>
      </Grid>
      <Grid item xs>
        <Grid container spacing={1}>
          <FormSelectField
            label="type"
            grid={6}
            options={CellTypes.map((type) => ({
              id: type,
              name: t(type),
            }))}
            value={item.type}
            onChange={(val) => onItemChange({ ...item, type: val })}
          />
          {item.type === "ACCOUNT" ? (
            <FormSelectField
              grid={6}
              label="account"
              options={accounts}
              value={item.value}
              onChange={(val) => onItemChange({ ...item, value: val })}
            />
          ) : (
            <FormTextField
              grid={6}
              label="label"
              value={item.value}
              onChange={(val) => onItemChange({ ...item, value: val })}
            />
          )}
        </Grid>
      </Grid>
    </Grid>
  );
}
