import React from "react";
import ReportManager from "commons/components/ReportManager";
import { columns, useInventoryReport } from "./InventoryLevels";

const filters = [
  { type: "filter", name: "facilities", key: "facility_id" },
  {
    type: "filter",
    name: "products",
    key: "product_id",
    filters: { stockable: true },
  },
];

export default function InventoryExpiry() {
  const { transformer } = useInventoryReport();

  return (
    <ReportManager
      filterByDate
      dateField="exp_date"
      url="stock-levels"
      title="expired"
      filters={filters}
      columns={columns}
      recordsFunc={transformer}
    />
  );
}
