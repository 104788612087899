import React from "react";
import ReportManager, {
  extractCurrencyName,
  groupByCurrency,
} from "commons/components/ReportManager";
import { columns } from "commons/pages/purchases/List";

const filters = [
  { type: "filter", name: "facilities", key: "facility_id" },
  {
    type: "filter",
    name: "users",
    key: "user_id",
    extras: { optionLabel: "username" },
  },
  { type: "filter", name: "suppliers", key: "supplier_id" },
];

export default function PurchasesByOperation() {
  return (
    <ReportManager
      url="purchases"
      title="purchasesByOperation"
      columns={columns}
      filterByDate
      filters={filters}
      recordsFunc={groupByCurrency}
      titlesFunc={extractCurrencyName}
      totals={["subtotal", "discount", "tax", "total", "commission", "cost"]}
    />
  );
}
