import React, { useCallback, useEffect, useMemo, useState } from "react";
import usePermissions from "commons/hooks/usePermissions";
import useFrontDeskData from "./useFrontDeskData";
import { Box, Fab, Grid, Zoom } from "@material-ui/core";
import FacilityProductsCard from "./cards/FacilityProductsCard";
import EntityCard from "./cards/EntityCard";
import CategoryTree from "../sales/components/CategoryTree";
import Stack from "commons/components/Stack";
import FormTextField from "commons/components/FormTextField";
import {
  add,
  append,
  assoc,
  compose,
  concat,
  equals,
  groupBy,
  mergeWith,
  prop,
  propEq,
  __,
} from "ramda";
import TotalsCard from "./cards/TotalsCard";
import PaymentCard from "./cards/PaymentCard";
import LinesCard from "./cards/LinesCard";
import {
  applyLineExtras,
  calculateModel,
  isForOp,
  lineFromProduct,
} from "./utils";
import { Save } from "@material-ui/icons";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useHistory, useLocation, useParams } from "react-router";
import api from "commons/helpers/api";
import dayjs from "dayjs";
import CloseCard from "../sales/components/CloseCard";
import ReceiptPrintCard from "./cards/ReceiptPrintCard";
import StocksPrintCard from "./cards/StocksPrintCard";
import PageCard from "commons/components/PageCard";
import ExtrasCard from "./cards/ExtrasCard";
import FrontdeskToolbar from "./FrontdeskToolbar";
import { rulesReducer, sumField } from "commons/helpers/utils";
import ErrorAlert from "commons/components/ErrorAlert";
import { prepareDiscount, prepareTax } from "./utils/initializers";
import StocksCard from "./cards/StocksCard";
import { Alert, AlertTitle } from "@material-ui/lab";
import useTranslate from "commons/hooks/useTranslate";
import { useFrontDeskStocks } from "./useFrontDeskStocks";
import TableManager from "./cards/TableManager";
import useFrontDeskContext from "commons/hooks/useFrontDeskContext";

const fetchResource = ({ queryKey }) => {
  const [base, id] = queryKey;
  return api.service(base).get(id);
};

const isValidationError = (error) => {
  return error.code === 422 && Array.isArray(error.data);
};

const getInitial = (extras = {}) => {
  const facility_id =
    parseInt(window.localStorage.getItem("fdDefaultFacility")) || null;
  const access_group_id =
    parseInt(window.localStorage.getItem("defaultAccessGroup")) || null;
  return {
    facility_id,
    access_group_id,
    date: dayjs().format("YYYY-MM-DDTHH:mm:ssZZ"),
    payments: [],
    lines: [],
    stocks: [],
    returns: [],
    discounts: [],
    taxes: [],
    commissions: [],
    total: 0,
    subtotal: 0,
    ...extras,
  };
};

export default function FrontDeskController({
  base = "sales",
  EntityManager = EntityCard,
  EntityExtras = null,
  tables = [],
  matchCustomerFacility = false,
}) {
  const { id } = useParams();
  const client = useQueryClient();
  const history = useHistory();
  const location = useLocation();
  const [rules, setRules] = useState({});
  // Read model from url query.
  const queryModel = useMemo(() => {
    const q = new URLSearchParams(location.search);
    const initial = q.get("model");
    return JSON.parse(initial) ?? {};
  }, [location]);
  const [model, setModel] = useState(() => {
    if (id === "create") return getInitial(queryModel);
    const d = client.getQueryData([base, parseInt(id)]);
    return d ? d : getInitial();
  });
  const [dirty, setDirty] = useState(false);
  const isCreating = id === "create";

  useEffect(() => {
    if (isCreating && Boolean(model.id)) {
      setModel(getInitial(queryModel));
    }
  }, [model.id, queryModel, isCreating]);

  const { error: queryError } = useQuery([base, parseInt(id)], fetchResource, {
    enabled: !isCreating,
    onSuccess: setModel,
  });

  const onDataChange = useCallback(
    (obj) => {
      if (model.id === obj.id && !equals(model, obj)) {
        client.invalidateQueries([base, model.id]);
      }
    },
    [client, model, base]
  );

  useEffect(() => {
    api.service(base).on("updated", onDataChange);
    api.service(base).on("patched", onDataChange);
    api.service(base).on("removed", onDataChange);
    return () => {
      api.service(base).removeListener("updated", onDataChange);
      api.service(base).removeListener("patched", onDataChange);
      api.service(base).removeListener("removed", onDataChange);
    };
  }, [base, onDataChange]);

  const { mutate, error: mutateError } = useMutation(
    ({ base, model }) => {
      if (!Boolean(model.id)) {
        return api.service(base).create(model);
      } else {
        return api.service(base).update(model.id, model);
      }
    },
    {
      onError: (error) => {
        setDirty(true);
        if (isValidationError(error)) {
          setRules(error.data.reduce(rulesReducer, {}));
        }
      },
      onSuccess: (data) => {
        if (id === "create") {
          client.setQueryData([base, data.id], data);
          history.push(`/s/${base}/${data.id}`);
        }
      },
    }
  );

  const onModelChange = (model) => {
    setDirty(true);
    setModel(calculateModel(base)(model));
  };

  const onSave = (onSaveSuccess = () => {}, m) => {
    if (dirty) {
      setDirty(false);
      setRules({});
      mutate(
        { base, model: m || model },
        {
          onSuccess: onSaveSuccess,
        }
      );
    } else {
      onSaveSuccess();
    }
  };

  return (
    <Frontdesk
      sales={base === "sales"}
      dirty={dirty}
      model={model}
      onChange={onModelChange}
      onSave={onSave}
      rules={rules}
      queryError={queryError}
      mutateError={mutateError}
      isCreating={isCreating}
      tables={tables}
      EntityManager={EntityManager}
      EntityExtras={EntityExtras}
      matchCustomerFacility={matchCustomerFacility}
    />
  );
}

function useToggle(name) {
  const [toggle, setToggle] = useState(
    () => JSON.parse(window.localStorage.getItem(name)) || false
  );

  const onToggle = () => {
    setToggle(!toggle);
    window.localStorage.setItem(name, !toggle);
  };

  return [toggle, onToggle];
}

const pairsMatch = (p1, p2) => p1[0] === p2[0] && p1[1] === p2[1];

function Frontdesk({
  model,
  onChange,
  onSave,
  sales = true,
  dirty = false,
  isCreating = false,
  matchCustomerFacility = false,
  rules = {},
  queryError,
  mutateError,
  tables = [],
  EntityManager = EntityCard,
  EntityExtras = null,
}) {
  const {
    facilities,
    currencies,
    policies,
    productPolicies,
    locations,
    discounts,
    commissions,
    taxes,
    employees,
    categories,
    components,
  } = useFrontDeskData();
  const { products, catalog, customers, suppliers, stockLevels } =
    useFrontDeskContext();
  const { t } = useTranslate();
  const { settings, hasPermission } = usePermissions();
  const [showCategories, toggleShowCategories] = useToggle("fdShowCategories");
  const [compactView, toggleCompactView] = useToggle("fdCompactView");
  const [phoneSearch, togglePhoneSearch] = useToggle("fdPhoneSearch");
  const payment_field = sales ? "customer_id" : "supplier_id";
  const [appliedActiveExtras, setAppliedActiveExtras] = useState([0, 0]);

  const onPayment = compose(
    onChange,
    assoc("payments", __, model),
    append(__, model.payments),
    assoc(payment_field, model[payment_field])
  );
  useEffect(() => {
    if (model.payments.some((p) => !Boolean(p.id))) {
      onSave();
    }
  }, [onSave, model.payments]);

  useEffect(() => {
    if (sales && isCreating) {
      const currD = discounts.filter(isForOp(model)).map(prepareDiscount);
      const currT = taxes.filter(isForOp(model)).map(prepareTax);
      const pair = [currD.length, currT.length];
      if (!pairsMatch(appliedActiveExtras, pair)) {
        setAppliedActiveExtras(pair);
        onChange({ ...model, discounts: currD, taxes: currT });
      }
    }
  }, [
    sales,
    isCreating,
    discounts,
    taxes,
    model,
    onChange,
    appliedActiveExtras,
  ]);

  useEffect(() => {
    if (model.policy_id) {
      setAppliedActiveExtras([0, 0]);
    }
  }, [model.policy_id]);

  // const filteredProducts = useMemo(
  //   () =>
  //     products.filter(
  //       (prod) =>
  //         prod.facility_id === model.facility_id &&
  //         prod[sales ? "saleable" : "purchasable"] === true
  //     ),
  //   [sales, products, model.facility_id]
  // );

  const filteredProducts = useMemo(() => {
    const filtered = sales ? catalog.saleable : catalog.purchasable;
    if (settings["showAllFacilitiesProducts"] && "all" in filtered) {
      return filtered["all"];
    } else if (model.facility_id && model.facility_id in filtered) {
      return filtered[model.facility_id];
    } else {
      return [];
    }
  }, [sales, catalog, model.facility_id, settings]);

  const canDoAction = useCallback(hasPermission(model.access_group_id), [
    hasPermission,
    model.access_group_id,
  ]);

  // FIXME: causes problems with the performance
  const orderedStocks = useMemo(() => {
    if (!model.facility_id) return stockLevels;
    const sorter = (a, b) => {
      const a_fac = Number(a.facility_id);
      const b_fac = Number(b.facility_id);
      const mod_fac = Number(model.facility_id);
      if (a_fac === b_fac && a_fac === mod_fac) {
        return Math.sign(Number(a.value) - Number(b.value));
      }
      if (a_fac === mod_fac || a_fac < b_fac) {
        return -1;
      }
      if (a_fac > b_fac) {
        return 1;
      }
      return 0;
    };
    const result = {};
    for(const curr of Object.keys(stockLevels)) {
        result[curr] = stockLevels[curr].sort(sorter);
    }
    // return Object.keys(stockLevels).reduce((acc, curr) => {
    //   return {
    //     ...acc,
    //     [curr]: stockLevels[curr].sort(sorter), //sortBy(sorter, stockLevels[curr]),
    //   };
    // }, {});
    return result;
  }, [stockLevels, model.facility_id]);

  const allStocks = useMemo(() => {
    const byProductID = groupBy(prop("product_id"));
    const lineStocks = model.lines
      .flatMap((l) => l.stocks)
      .filter((s) => !Boolean(s.operation_line_id));
    return mergeWith(concat, orderedStocks, byProductID(lineStocks));
  }, [orderedStocks, model.lines]);

  // FIXME: causes problems with the performance
  const productOwnStocks = useMemo(() => {
    const result = {};
    for (const p of products) {
      const { product_id } = p;
      result[product_id] = sumField("quantity")(allStocks[product_id] || []);
    }
    // return products.reduce(
    //   (acc, { product_id }) => ({
    //     ...acc,
    //     [product_id]: sumField("quantity")(allStocks[product_id] || []),
    //   }),
    //   {}
    // );
    return result;
  }, [allStocks, products]);

  const productCompStocks = useMemo(() => {
    const result = {};
    for (const p of products) {
      const myComponents = components.filter((c) => {
        return Number(c.product_id) === Number(p.product_id);
      });
      const compStocks = myComponents.map((comp) => {
        const { component_id, product_ratio, component_ratio } = comp;
        const total = productOwnStocks[component_id] || 0;
        return total * (product_ratio / component_ratio);
      });
      result[p.product_id] = myComponents.length > 0 ? Math.floor(Math.min(...compStocks)) : 0;
    }
    return result;
    // return products.reduce((acc, p) => {
    //   const myComponents = components.filter((c) => {
    //     return Number(c.product_id) === Number(p.product_id);
    //   });
    //   const compStocks = myComponents.map((comp) => {
    //     const { component_id, product_ratio, component_ratio } = comp;
    //     const total = productOwnStocks[component_id] || 0;
    //     return total * (product_ratio / component_ratio);
    //   });
    //   return {
    //     ...acc,
    //     [p.product_id]:
    //       myComponents.length > 0 ? Math.floor(Math.min(...compStocks)) : 0,
    //   };
    // }, {});
  }, [productOwnStocks, products, components]);

  const stockTotals = useMemo(
    () => mergeWith(add, productOwnStocks, productCompStocks),
    [productOwnStocks, productCompStocks]
  );

  const {
    error,
    updateLineField,
    mergeLineFields,
    onChangeLineQuantity,
    onChangeQuantity,
    onChangeReturned,
    onDeleteLine,
  } = useFrontDeskStocks({
    sales,
    model,
    onChange,
    products,
    components,
    settings,
    canDoAction,
    productOwnStocks,
    productCompStocks,
    stockTotals,
    stockLevels: orderedStocks,
  });

  const onProductSelect = (product_id, qty = 1) => {
    const isSameProduct = propEq("product_id", product_id);
    const index = model.lines.findIndex(isSameProduct);
    const product = products.find(isSameProduct);
    if (!product) return;

    if (index !== -1) {
      const line = model.lines[index];
      onChangeQuantity(line.id)(Number(line.quantity) + Number(qty));
    } else {
      const line = sales
        ? applyLineExtras(
            discounts,
            taxes,
            commissions,
            model,
            product
          )(lineFromProduct(product, productPolicies, model.policy_id, sales))
        : lineFromProduct(product, productPolicies, model.policy_id, sales);
      onChangeLineQuantity([...model.lines, line])(line.id)(qty);
    }
  };

  const productByCategory = useMemo(() => {
    const result = {};
    for (const p of products) {
      result[p.product_id] = p.category_id;
    }
    // return products.reduce(
    //   (acc, p) => ({ ...acc, [p.product_id]: p.category_id }),
    //   {}
    // );
    return result;
  }, [products]);

  const categoryNames = useMemo(() => {
    return categories.reduce((acc, c) => ({ ...acc, [c.id]: c.name }), {});
  }, [categories]);

  const linesByCategory = useMemo(() => {
    const groupedByCategory = model.lines.reduce((acc, l) => {
      const cat = productByCategory[l.product_id];
      if (cat in acc) {
        return { ...acc, [cat]: [...acc[cat], l] };
      } else {
        return { ...acc, [cat]: [l] };
      }
    }, {});
    return Object.keys(groupedByCategory).reduce((acc, k) => {
      return {
        ...acc,
        [k]: {
          category_id: k,
          name: categoryNames[k],
          total: sumField("total")(groupedByCategory[k]),
        },
      };
    }, {});
  }, [model.lines, productByCategory, categoryNames]);

  return (
    <PageCard>
      <FrontdeskToolbar
        sales={sales}
        model={model}
        onChange={onChange}
        toggleShowCategories={toggleShowCategories}
        toggleCompactView={toggleCompactView}
        togglePhoneSearch={togglePhoneSearch}
      />
      <br />
      <ErrorAlert error={error ? { message: error } : null} />
      <ErrorAlert error={queryError} />
      <ErrorAlert error={mutateError} />
      {rules.lines && (
        <Alert elevation={6} variant="filled" severity="error">
          <AlertTitle>{t("mustHaveLines")}</AlertTitle>
        </Alert>
      )}
      <Grid container spacing={2}>
        <Grid item xs={compactView ? 6 : 8}>
          <FacilityProductsCard
            model={model}
            onChange={onChange}
            facilities={facilities}
            products={filteredProducts}
            onProductSelect={onProductSelect}
            sales={sales}
            compact={compactView}
            rules={rules}
          />
        </Grid>
        <Grid item xs={compactView ? 6 : 4}>
          <EntityManager
            model={model}
            onChange={onChange}
            isCreating={isCreating}
            base={sales ? "customers" : "suppliers"}
            entities={sales ? customers : suppliers}
            compact={compactView}
            phoneSearch={phoneSearch}
            matchFacility={matchCustomerFacility}
            rules={rules}
            policies={policies}
            productPolicies={productPolicies}
            products={products}
            currencies={currencies}
          />
          {EntityExtras && (
            <EntityExtras model={model} onChange={onChange} rules={rules} />
          )}
        </Grid>
        {showCategories && (
          <CategoryTree
            base={sales ? "sales" : "purchases"}
            categories={categories}
            products={filteredProducts}
            onProductSelect={onProductSelect}
          />
        )}
        {tables.length > 0 && (
          <Grid item xs={12}>
            <TableManager model={model} onChange={onChange} tables={tables} />
          </Grid>
        )}
        <Grid item sm={9}>
          <Stack>
            <LinesCard
              sales={sales}
              model={model}
              locations={locations}
              discounts={discounts}
              taxes={taxes}
              commissions={commissions}
              canDoAction={canDoAction}
              products={filteredProducts}
              totals={stockTotals}
              updateLineField={updateLineField}
              mergeLineFields={mergeLineFields}
              onChangeQuantity={onChangeQuantity}
              onChangeReturned={onChangeReturned}
              onDeleteLine={onDeleteLine}
            />
            <Grid container spacing={2}>
              <Grid item sm={4}>
                <ReceiptPrintCard
                  model={model}
                  linesByCategory={linesByCategory}
                  base={sales ? "sales" : "purchases"}
                  tables={tables}
                  onPrintRequested={onSave}
                />
              </Grid>
              <Grid item sm={4}>
                <StocksPrintCard
                  model={model}
                  onPrintRequested={onSave}
                  products={products}
                  facilities={facilities}
                  tables={tables}
                  printComponents={settings["printComponents"]}
                  autoFulfilOnPrint={settings["autoFulfilOnPrint"]}
                />
              </Grid>
              <Grid item sm={4}>
                <CloseCard
                  value={model.closed}
                  onChange={compose(onChange, assoc("closed", __, model))}
                />
              </Grid>
            </Grid>
            <StocksCard
              model={model}
              onChange={onChange}
              products={products}
              facilities={facilities}
              manualStockChange={!settings["autoStockChange"]}
              printComponents={settings["printComponents"]}
              canDoAction={canDoAction}
            />
          </Stack>
        </Grid>
        <Grid item sm={3}>
          <Stack>
            <TotalsCard
              model={model}
              showCost={canDoAction("show-operation-cost")}
              showProfit={sales && canDoAction("show-operation-profit")}
              compact={compactView}
            />
            <PaymentCard
              model={model}
              onPayment={onPayment}
              currencies={currencies}
            />
            <ExtrasCard
              items={model.discounts}
              options={discounts}
              title="discounts"
              onChange={compose(onChange, assoc("discounts", __, model))}
              canDoAction={canDoAction}
            />
            <ExtrasCard
              items={model.taxes}
              options={taxes}
              title="taxes"
              onChange={compose(onChange, assoc("taxes", __, model))}
              canDoAction={canDoAction}
            />
            <ExtrasCard
              items={model.commissions}
              options={employees}
              title="commissions"
              onChange={compose(onChange, assoc("commissions", __, model))}
              canDoAction={canDoAction}
            />
          </Stack>
        </Grid>
        <FormTextField
          grid={6}
          multiline
          label="print_notes"
          value={model.print_notes}
          onChange={compose(onChange, assoc("print_notes", __, model))}
          error={rules.print_notes}
        />
        <FormTextField
          grid={6}
          multiline
          label="notes"
          value={model.notes}
          onChange={compose(onChange, assoc("notes", __, model))}
          error={rules.notes}
        />
      </Grid>
      <Box position="fixed" bottom={24} right={24} className="no-print">
        <Zoom in style={{ transitionDelay: "500ms" }}>
          <Fab
            type="submit"
            color="primary"
            onClick={() => onSave()}
            disabled={!dirty}
            id="resource-save-btn"
          >
            <Save />
          </Fab>
        </Zoom>
      </Box>
    </PageCard>
  );
}
