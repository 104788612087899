import React from "react";
import { Route, Switch } from "react-router-dom";
import SoftwareLogo from "../../logo.png";
import MainDashboard from "commons/components/MainDashboard";

import Home from "commons/pages/base/Home";
import ChangePassword from "commons/pages/base/ChangePassword";
import BaseSettings from "commons/pages/base/BaseSettings";
import BarcodePrinting from "commons/pages/base/BarcodePrinting";
import FastDiscount from "commons/pages/base/FastDiscount";
import LanguageList from "commons/pages/languages/List";
import LanguageSingle from "commons/pages/languages/Single";
import PrintTemplateList from "commons/pages/print-templates/List";
import PrintTemplateSingle from "commons/pages/print-templates/Single";
import Translations from "commons/pages/translations/List";

import SaleList from "commons/pages/sales2/List";
// import SaleSingle from "commons/pages/sales2/Single";
import FrontDeskController from "commons/pages/sales2/FrontDeskController";
import PurchaseList from "commons/pages/purchases/List";
import TransferList from "commons/pages/transfers/List";
import TransferSingle from "commons/pages/transfers/Single";
import CustomerList from "commons/pages/customers/List";
import CustomerSingle from "commons/pages/customers/Controller";
import BalanceStatement from "commons/pages/customers/BalanceStatement";
import SupplierList from "commons/pages/suppliers/List";
import SupplierSingle from "commons/pages/suppliers/Controller";
import ContractorList from "commons/pages/contractors/List";
import ContractorSingle from "commons/pages/contractors/Controller";
import SessionList from "commons/pages/sessions/List";
import SessionSingle from "commons/pages/sessions/Single";
import SessionAttendanceManager from "commons/pages/session-attendances/Manager";

import ProductList from "commons/pages/products/List";
import ProductSingle from "commons/pages/products/Single";
import ServiceList from "commons/pages/services/List";
import Serviceingle from "commons/pages/services/Single";
import CategoryList from "commons/pages/categories/List";
import CategorySingle from "commons/pages/categories/Single";
import PolicyList from "commons/pages/policies/List";
import PolicySingle from "commons/pages/policies/Controller";
import ProjectList from "commons/pages/projects/List";
import ProjectSingle from "commons/pages/projects/Single";
import ServiceLocationList from "commons/pages/service-locations/List";
import ServiceLocationSingle from "commons/pages/service-locations/Single";

import CurrencyList from "commons/pages/currencies/List";
import CurrencySingle from "commons/pages/currencies/Single";
import AccountsManager from "commons/pages/accounts/AccountsManager";
import TransactionList from "commons/pages/transactions/List";
import TransactionSingle from "commons/pages/transactions/Single";
import AssetGroupList from "commons/pages/asset-groups/List";
import AssetGroupSingle from "commons/pages/asset-groups/Single";
import AssetList from "commons/pages/assets/List";
import AssetSingle from "commons/pages/assets/Single";
import DiscountList from "commons/pages/discounts/List";
import DiscountSingle from "commons/pages/discounts/Single";
import TaxList from "commons/pages/taxes/List";
import TaxSingle from "commons/pages/taxes/Single";

import ContactList from "commons/pages/contacts/List";
import ContactSingle from "commons/pages/contacts/Single";
import FormTemplateList from "commons/pages/form-templates/List";
import TemplateSingle from "commons/pages/form-templates/Single";
import FormList from "commons/pages/forms/List";
import FormSingle from "commons/pages/forms/Single";
import AttachmentList from "commons/pages/attachments/List";
import AttachmentSingle from "commons/pages/attachments/Single";
import MessageManager from "commons/pages/messages/Manager";
import MessageSingle from "commons/pages/messages/Single";
import TaskList from "commons/pages/tasks/List";
import TaskSingle from "commons/pages/tasks/Single";

import DepartmentList from "commons/pages/departments/List";
import DepartmentSingle from "commons/pages/departments/Single";
import EmployeeList from "commons/pages/employees/List";
import EmployeeSingle from "commons/pages/employees/Controller";
import EvaluationList from "commons/pages/evaluations/List";
import EvaluationSingle from "commons/pages/evaluations/Single";
import RewardList from "commons/pages/rewards/List";
import RewardSingle from "commons/pages/rewards/Single";
import PenaltyList from "commons/pages/penalties/List";
import PenaltySingle from "commons/pages/penalties/Single";
import AttendanceList from "commons/pages/attendances/List";
import AttendanceSingle from "commons/pages/attendances/Single";
import AttendanceByShift from "commons/pages/attendances/ManageByShift";
import SalaryList from "commons/pages/salaries/List";
import SalarySingle from "commons/pages/salaries/Single";
import ShiftList from "commons/pages/shifts/List";
import ShiftSingle from "commons/pages/shifts/Single";
import ScheduleList from "commons/pages/schedules/List";
import ScheduleSingle from "commons/pages/schedules/Single";
import GradeList from "commons/pages/grades/List";
import GradeSingle from "commons/pages/grades/Single";
import LeaveTypeList from "commons/pages/leave-types/List";
import LeaveTypeSingle from "commons/pages/leave-types/Single";
import LeaveList from "commons/pages/leaves/List";
import LeaveSingle from "commons/pages/leaves/Single";
import VacationDayList from "commons/pages/vacation-days/List";
import VacationDaySingle from "commons/pages/vacation-days/Single";

import FacilityList from "commons/pages/facilities/List";
import FacilitySingle from "commons/pages/facilities/Controller";
import AccessGroupList from "commons/pages/access-groups/List";
import AccessGroupSingle from "commons/pages/access-groups/Single";
import UserList from "commons/pages/users/List";
import UserSingle from "commons/pages/users/Single";
import RoleList from "commons/pages/roles/List";
import RoleSingle from "commons/pages/roles/Single";

import ReportsIndex from "commons/pages/reports/ReportsIndex";

import SalesByOperation from "commons/pages/reports/sales/SalesByOperation";
import SalesByProduct from "commons/pages/reports/sales/SalesByProduct";
import SalesByCustomer from "commons/pages/reports/sales/SalesByCustomer";
import SalesByCategory from "commons/pages/reports/sales/SalesByCategory";
import SalesByUser from "commons/pages/reports/sales/SalesByUser";
import PurchasesByOperation from "commons/pages/reports/purchases/PurchasesByOperation";
import PurchasesByProduct from "commons/pages/reports/purchases/PurchasesByProduct";
import PurchasesBySupplier from "commons/pages/reports/purchases/PurchasesBySupplier";
import PurchasesByCategory from "commons/pages/reports/purchases/PurchasesByCategory";
import PurchasesByUser from "commons/pages/reports/purchases/PurchasesByUser";
import InventoryLevels from "commons/pages/reports/inventory/InventoryLevels";
import InventoryMovements from "commons/pages/reports/inventory/InventoryMovements";
import InventoryExpiry from "commons/pages/reports/inventory/InventoryExpiry";
import InventoryShortages from "commons/pages/reports/inventory/InventoryShortages";

import CustomersReport from "commons/pages/reports/persons/CustomersReport";
import SuppliersReport from "commons/pages/reports/persons/SuppliersReport";
import ContractorsReport from "commons/pages/reports/persons/ContractorsReport";
import EmployeesReport from "commons/pages/reports/persons/EmployeesReport";

import FinanceOverview from "commons/pages/reports/accounting/FinanceOverview";
import AccountsBalance from "commons/pages/reports/accounting/AccountsBalance";
import CurrenciesBalance from "commons/pages/reports/accounting/CurrenciesBalance";
import TransactionsReport from "commons/pages/reports/accounting/TransactionsReport";
import AccountingReports from "commons/pages/reports/accounting/AccountingReports";
import AccountingTemplateList from "commons/pages/accounting-templates/List";
import AccountingTemplateSingle from "commons/pages/accounting-templates/Single";

import OverviewReport from "commons/pages/reports/misc/OverviewReport";
import FormsReport from "commons/pages/reports/misc/FormsReport";
import TasksReport from "commons/pages/reports/misc/TasksReport";
import OngoingServicesReport from "commons/pages/reports/misc/OngoingServices";
import EndingServicesReport from "commons/pages/reports/misc/EndingServices";
import AuditReport from "commons/pages/reports/misc/AuditReport";
import InventoryValue from "commons/pages/reports/inventory/InventoryValue";
import SerialByCustomer from "commons/pages/reports/inventory/SerialByCustomer";
// import { Button } from "@material-ui/core";
// import api from "commons/helpers/api";
// import dayjs from "dayjs";

const empQuery = { permanent: true };
const empQualifiedQuery = { "employees.permanent": true };

// function getRandomInt(min, max) {
//   min = Math.ceil(min);
//   max = Math.floor(max);
//   return Math.floor(Math.random() * (max - min)) + min; //The maximum is exclusive and the minimum is inclusive
// }

export default function Dashboard({ companyName = "", logo, ...props }) {
  // const addProducts = async () => {
  //   for (let i = 1; i <= 10000; i++) {
  //     console.log("Before", i);
  //     const price = getRandomInt(200, 10000);
  //     const prod = {
  //       name: "Product #" + i,
  //       code: i + 1,
  //       sale_price: price,
  //       purchase_price: price - 100,
  //       cost_price: price - 50,
  //       saleable: true,
  //       purchasable: true,
  //       stockable: true,
  //       active: true,
  //       type: "PIECE",
  //       access_group_id: 1,
  //       facilities: [
  //         {
  //           product_id: i,
  //           facility_id: 1,
  //         },
  //       ],
  //     };
  //     await api.service("products").create(prod);
  //     console.log("After", i);
  //   }
  // };

  // const addCustomers = async () => {
  //   const before = Date.now();
  //   for (let i = 1; i <= 100000; i++) {
  //     const policy = getRandomInt(1, 3);
  //     const cust = {
  //       name: "Customer #" + i,
  //       policy_id: policy,
  //       access_group_id: 1,
  //     };
  //     await api.service("customers").create(cust);
  //     console.log("After", i);
  //   }
  //   const after = Date.now();
  //   const elapsed = after - before;
  //   console.log(`Operation Took: ${elapsed / 1000} seconds`);
  // };

  // const addOperations = (base) => async () => {
  //   const before = Date.now();
  //   const entity = base === "sales" ? "customer_id" : "supplier_id";
  //   const policy = base === "sales" ? 1 : 4;
  //   const count = base === "sales" ? 1000000 : 10000;
  //   for (let i = 1; i <= count; i++) {
  //     const prod = getRandomInt(1, 10000);
  //     const price = getRandomInt(200, 10000);
  //     const pur = {
  //       facility_id: 1,
  //       access_group_id: 1,
  //       date: dayjs(),
  //       payments: [],
  //       lines: [
  //         {
  //           id: "4bea1eb3-f4c8-4c01-962b-b87990adefa8",
  //           name: "Product #" + prod,
  //           code: prod + 1,
  //           type: "PIECE",
  //           product_id: prod,
  //           date: dayjs(),
  //           savedQty: 0,
  //           savedReturned: 0,
  //           savedCost: 0,
  //           quantity: 3,
  //           returned: 0,
  //           actual: 3,
  //           stocks: [
  //             {
  //               id: "782115a6-8f88-40bb-b2fc-d913b7ea7965",
  //               facility_id: 1,
  //               product_id: prod,
  //               value: price,
  //               quantity: 3,
  //               requested: "2021-06-03T13:59:53.764Z",
  //               fulfilled: "2021-06-03T13:59:53.764Z",
  //               serial: null,
  //               mfg_date: null,
  //               exp_date: null,
  //               total_value: price * 3,
  //             },
  //           ],
  //           carRentals: [],
  //           discounts: [],
  //           taxes: [],
  //           commissions: [],
  //           price: price,
  //           cost_price: price,
  //           subtotal: price,
  //           discount: 0,
  //           tax: 0,
  //           commission: 0,
  //           total: price * 3,
  //           cost: price * 3,
  //           profit: 0,
  //         },
  //         {
  //           id: "ccd6ba65-f46f-4d47-b994-dc60bf44d68f",
  //           name: "Product #" + (prod + 1),
  //           code: prod + 2,
  //           type: "PIECE",
  //           product_id: prod + 1,
  //           date: dayjs(),
  //           savedQty: 0,
  //           savedReturned: 0,
  //           savedCost: 0,
  //           quantity: 2,
  //           returned: 0,
  //           actual: 2,
  //           stocks: [
  //             {
  //               id: "ebcd081b-4365-418a-aa6c-1aa0fcc03643",
  //               facility_id: 1,
  //               product_id: prod + 1,
  //               value: price + 100,
  //               quantity: 2,
  //               requested: "2021-06-03T13:59:55.546Z",
  //               fulfilled: "2021-06-03T13:59:55.546Z",
  //               serial: null,
  //               mfg_date: null,
  //               exp_date: null,
  //               total_value: (price + 100) * 2,
  //             },
  //           ],
  //           carRentals: [],
  //           discounts: [],
  //           taxes: [],
  //           commissions: [],
  //           price: price + 100,
  //           cost_price: price + 100,
  //           subtotal: (price + 100) * 2,
  //           discount: 0,
  //           tax: 0,
  //           commission: 0,
  //           total: (price + 100) * 2,
  //           cost: (price + 100) * 2,
  //           profit: 0,
  //         },
  //       ],
  //       stocks: [],
  //       returns: [],
  //       discounts: [],
  //       taxes: [],
  //       commissions: [],
  //       total: (price + 100) * 2 + price * 3,
  //       subtotal: (price + 100) * 2 + price * 3,
  //       [entity]: 1,
  //       policy_id: policy,
  //       summary: `(3 Product #${prod}) (2 Product #${prod + 1}) `,
  //       discount: 0,
  //       commission: 0,
  //       tax: 0,
  //       cost: (price + 100) * 2 + price * 3,
  //       profit: 0,
  //     };
  //     await api.service(base).create(pur);
  //     console.log("After", i);
  //   }
  //   const after = Date.now();
  //   const elapsed = after - before;
  //   console.log(`Operation Took: ${elapsed / 1000} seconds`);
  // };

  // const addPurchases = addOperations("purchases");
  // const addSales = addOperations("sales");

  return (
    <MainDashboard
      SoftwareLogo={SoftwareLogo}
      companyName={companyName}
      messages
      notifications
      {...props}
    >
      {/* <Button variant="contained" color="primary" onClick={addProducts}>
        Products
      </Button>
      <Button variant="contained" color="primary" onClick={addCustomers}>
        Customers
      </Button>
      <Button variant="contained" color="primary" onClick={addPurchases}>
        Purchases
      </Button>
      <Button variant="contained" color="primary" onClick={addSales}>
        Sales
      </Button> */}
      <Switch>
        <Route path="/s/sales/:id">
          <FrontDeskController base="sales" />
        </Route>
        <Route path="/s/sales" component={SaleList} />
        <Route path="/s/purchases/:id">
          <FrontDeskController base="purchases" />
        </Route>
        <Route path="/s/purchases" component={PurchaseList} />
        <Route path="/s/transfers/:id" component={TransferSingle} />
        <Route path="/s/transfers" component={TransferList} />
        <Route
          path="/s/customers/:id/balance-statement"
          component={BalanceStatement}
        />
        <Route path="/s/customers/:id" component={CustomerSingle} />
        <Route path="/s/customers" component={CustomerList} />
        <Route path="/s/suppliers/:id/balance-statement">
          <BalanceStatement
            base="suppliers"
            field="supplier_id"
            operation="purchases"
            payments_service="supplier-payments"
          />
        </Route>
        <Route path="/s/suppliers/:id" component={SupplierSingle} />
        <Route path="/s/suppliers" component={SupplierList} />
        <Route path="/s/contractors/:id" component={ContractorSingle} />
        <Route path="/s/contractors" component={ContractorList} />
        <Route path="/s/sessions/:id" component={SessionSingle} />
        <Route path="/s/sessions" component={SessionList} />
        <Route path="/s/products/:id" component={ProductSingle} />
        <Route path="/s/products" component={ProductList} />
        <Route path="/s/services/:id" component={Serviceingle} />
        <Route path="/s/services" component={ServiceList} />
        <Route path="/s/categories/:id" component={CategorySingle} />
        <Route path="/s/categories" component={CategoryList} />
        <Route path="/s/policies/:id" component={PolicySingle} />
        <Route path="/s/policies" component={PolicyList} />
        <Route path="/s/projects/:id" component={ProjectSingle} />
        <Route path="/s/projects" component={ProjectList} />
        <Route
          path="/s/service-locations/:id"
          component={ServiceLocationSingle}
        />
        <Route path="/s/service-locations" component={ServiceLocationList} />
        <Route
          path="/s/session-attendances"
          component={SessionAttendanceManager}
        />

        <Route path="/s/currencies/:id" component={CurrencySingle} />
        <Route path="/s/currencies" component={CurrencyList} />
        <Route path="/s/accounts" component={AccountsManager} />
        <Route path="/s/transactions/:id" component={TransactionSingle} />
        <Route path="/s/transactions" component={TransactionList} />
        <Route path="/s/asset-groups/:id" component={AssetGroupSingle} />
        <Route path="/s/asset-groups" component={AssetGroupList} />
        <Route path="/s/assets/:id" component={AssetSingle} />
        <Route path="/s/assets" component={AssetList} />
        <Route path="/s/discounts/:id" component={DiscountSingle} />
        <Route path="/s/discounts" component={DiscountList} />
        <Route path="/s/taxes/:id" component={TaxSingle} />
        <Route path="/s/taxes" component={TaxList} />

        <Route path="/s/contacts/:id" component={ContactSingle} />
        <Route path="/s/contacts" component={ContactList} />
        <Route path="/s/form-templates/:id" component={TemplateSingle} />
        <Route path="/s/form-templates" component={FormTemplateList} />
        <Route path="/s/forms/:id" component={FormSingle} />
        <Route path="/s/forms" component={FormList} />
        <Route path="/s/attachments/:id" component={AttachmentSingle} />
        <Route path="/s/attachments" component={AttachmentList} />
        <Route path="/s/messages/:id" component={MessageSingle} />
        <Route path="/s/messages" component={MessageManager} />
        <Route path="/s/tasks/:id" component={TaskSingle} />
        <Route path="/s/tasks" component={TaskList} />
        <Route path="/s/my-tasks/:id">
          <TaskSingle base="my-tasks" />
        </Route>
        <Route path="/s/my-tasks">
          <TaskList url="my-tasks" />
        </Route>
        <Route path="/s/departments/:id" component={DepartmentSingle} />
        <Route path="/s/departments" component={DepartmentList} />
        <Route path="/s/employees/:id" component={EmployeeSingle} />
        <Route path="/s/employees">
          <EmployeeList query={empQuery} />
        </Route>
        <Route path="/s/evaluations/:id">
          <EvaluationSingle query={empQuery} />
        </Route>
        <Route path="/s/evaluations">
          <EvaluationList query={empQualifiedQuery} />
        </Route>
        <Route path="/s/rewards/:id">
          <RewardSingle query={empQuery} />
        </Route>
        <Route path="/s/rewards">
          <RewardList query={empQualifiedQuery} />
        </Route>
        <Route path="/s/penalties/:id">
          <PenaltySingle query={empQuery} />
        </Route>
        <Route path="/s/penalties">
          <PenaltyList query={empQualifiedQuery} />
        </Route>
        <Route path="/s/leaves/:id">
          <LeaveSingle query={empQuery} />
        </Route>
        <Route path="/s/leaves">
          <LeaveList query={empQualifiedQuery} />
        </Route>
        <Route path="/s/salaries/:id">
          <SalarySingle query={empQuery} />
        </Route>
        <Route path="/s/salaries">
          <SalaryList query={empQualifiedQuery} />
        </Route>
        <Route path="/s/attendances/manage-by-shift">
          <AttendanceByShift query={empQuery} />
        </Route>
        <Route path="/s/attendances/:id">
          <AttendanceSingle query={empQuery} />
        </Route>
        <Route path="/s/attendances">
          <AttendanceList query={empQualifiedQuery} />
        </Route>
        <Route path="/s/shifts/:id" component={ShiftSingle} />
        <Route path="/s/shifts" component={ShiftList} />
        <Route path="/s/schedules/:id">
          <ScheduleSingle query={empQuery} />
        </Route>
        <Route path="/s/schedules">
          <ScheduleList query={empQualifiedQuery} />
        </Route>
        <Route path="/s/grades/:id" component={GradeSingle} />
        <Route path="/s/grades" component={GradeList} />
        <Route path="/s/leave-types/:id" component={LeaveTypeSingle} />
        <Route path="/s/leave-types" component={LeaveTypeList} />
        <Route path="/s/vacation-days/:id" component={VacationDaySingle} />
        <Route path="/s/vacation-days" component={VacationDayList} />

        <Route
          path="/s/reports/sales-by-operation"
          component={SalesByOperation}
        />
        <Route path="/s/reports/sales-by-product" component={SalesByProduct} />
        <Route
          path="/s/reports/sales-by-category"
          component={SalesByCategory}
        />
        <Route
          path="/s/reports/sales-by-customer"
          component={SalesByCustomer}
        />
        <Route path="/s/reports/sales-by-user" component={SalesByUser} />
        <Route
          path="/s/reports/purchases-by-operation"
          component={PurchasesByOperation}
        />
        <Route
          path="/s/reports/purchases-by-product"
          component={PurchasesByProduct}
        />
        <Route
          path="/s/reports/purchases-by-category"
          component={PurchasesByCategory}
        />
        <Route
          path="/s/reports/purchases-by-supplier"
          component={PurchasesBySupplier}
        />
        <Route
          path="/s/reports/purchases-by-user"
          component={PurchasesByUser}
        />
        <Route path="/s/reports/stock-levels" component={InventoryLevels} />
        <Route path="/s/reports/stock-value" component={InventoryValue} />
        <Route
          path="/s/reports/stock-movements"
          component={InventoryMovements}
        />
        <Route
          path="/s/reports/serial-by-customer"
          component={SerialByCustomer}
        />
        <Route
          path="/s/reports/stock-shortages"
          component={InventoryShortages}
        />
        <Route path="/s/reports/stock-expired" component={InventoryExpiry} />

        <Route path="/s/reports/customers" component={CustomersReport} />
        <Route path="/s/reports/suppliers" component={SuppliersReport} />
        <Route path="/s/reports/contractors" component={ContractorsReport} />
        <Route path="/s/reports/employees" component={EmployeesReport} />

        <Route path="/s/reports/finance-overview" component={FinanceOverview} />
        <Route path="/s/reports/accounts-balance" component={AccountsBalance} />
        <Route
          path="/s/reports/currencies-balance"
          component={CurrenciesBalance}
        />
        <Route path="/s/reports/transactions" component={TransactionsReport} />
        <Route
          path="/s/reports/accounting-reports"
          component={AccountingReports}
        />
        <Route
          path="/s/reports/accounting-reports-templates/:id"
          component={AccountingTemplateSingle}
        />
        <Route
          path="/s/reports/accounting-reports-templates"
          component={AccountingTemplateList}
        />

        <Route path="/s/reports/overview" component={OverviewReport} />
        <Route path="/s/reports/tasks" component={TasksReport} />
        <Route path="/s/reports/forms" component={FormsReport} />
        <Route path="/s/reports/audit" component={AuditReport} />
        <Route
          path="/s/reports/ongoing-services"
          component={OngoingServicesReport}
        />
        <Route
          path="/s/reports/ending-services"
          component={EndingServicesReport}
        />
        <Route path="/s/reports" component={ReportsIndex} />

        <Route path="/s/facilities/:id" component={FacilitySingle} />
        <Route path="/s/facilities" component={FacilityList} />
        <Route path="/s/access-groups/:id" component={AccessGroupSingle} />
        <Route path="/s/access-groups" component={AccessGroupList} />
        <Route path="/s/users/:id" component={UserSingle} />
        <Route path="/s/users" component={UserList} />
        <Route path="/s/roles/:id" component={RoleSingle} />
        <Route path="/s/roles" component={RoleList} />
        <Route path="/s/app-settings" component={BaseSettings} />
        <Route path="/s/print-templates/:id" component={PrintTemplateSingle} />
        <Route path="/s/print-templates" component={PrintTemplateList} />
        <Route path="/s/barcode-printing" component={BarcodePrinting} />
        <Route path="/s/fast-discount" component={FastDiscount} />
        <Route path="/s/languages/:id" component={LanguageSingle} />
        <Route path="/s/languages" component={LanguageList} />
        <Route path="/s/translations" component={Translations} />
        <Route path="/s/change-password" component={ChangePassword} />
        <Route exact path="/">
          <Home logo={logo} companyName={companyName} />
        </Route>
      </Switch>
    </MainDashboard>
  );
}
