import React from "react";
import ResourceEditor from "commons/components/ResourceEditor";
import FormTextField from "commons/components/FormTextField";

export default function Editor({ current, send, updateModel, ...props }) {
  const { model, rules } = current.context;

  return (
    <ResourceEditor current={current} send={send} {...props}>
      <FormTextField
        grid={6}
        label="name"
        value={model.name}
        onChange={updateModel("name")}
        error={rules.name}
      />
      <FormTextField
        grid={6}
        label="address"
        value={model.address}
        onChange={updateModel("address")}
        error={rules.address}
      />
      <FormTextField
        multiline
        label="description"
        value={model.description}
        onChange={updateModel("description")}
        error={rules.description}
      />
    </ResourceEditor>
  );
}
