import React from "react";
import ResourceListPage from "commons/components/ResourceListPage";
import { Importer } from "commons/components/Importer";

const columns = [
  {
    name: "id",
    type: "number",
    sortable: true,
    searchable: true,
    searchKey: "customers.id",
  },
  {
    name: "name",
    type: "text",
    sortable: true,
    searchable: true,
    searchKey: "customers.name",
  },
  {
    name: "address",
    type: "text",
    sortable: true,
    searchable: true,
  },
  {
    name: "primary_phone",
    type: "text",
    sortable: true,
    searchable: true,
  },
  {
    name: "secondary_phone",
    type: "text",
    sortable: true,
    searchable: true,
  },
  {
    name: "email",
    type: "text",
    sortable: true,
    searchable: true,
  },
  {
    name: "dob",
    type: "date",
    sortable: true,
    searchable: true,
  },
  {
    name: "policy",
    type: "text",
    sortable: true,
    searchable: true,
    searchKey: "policies.name",
  },
  {
    name: "notes",
    sortable: true,
    searchable: true,
    type: "text",
  },
];

export default function List({ title = "customers" }) {
  return (
    <ResourceListPage
      url="customers"
      columns={columns}
      title={title}
      actions={
        <>
          <Importer
            base="customers"
            columns={[
              "id",
              "name",
              "address",
              "primary_phone",
              "secondary_phone",
              "email",
              "dob",
              "policy_id",
              "balance",
            ]}
            beforeSave={(records) => {
              const recs = records.map((rec) => ({
                ...rec,
                dob: rec.dob || null,
                payments: rec.balance
                  ? [
                      {
                        method: "CASH",
                        date: new Date(),
                        amount: Math.floor(rec.balance * 100),
                        currency_id: 1,
                        rate: 1,
                        amount_in_currency: Math.floor(rec.balance * 100),
                      },
                    ]
                  : [],
              }));
              return recs;
            }}
          />
        </>
      }
    />
  );
}
