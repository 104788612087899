import React from "react";
import ResourceListPage from "commons/components/ResourceListPage";

const columns = [
  {
    name: "name",
    type: "text",
    sortable: true,
    searchable: true,
  },
  {
    name: "address",
    type: "text",
    sortable: true,
    searchable: true,
  },
  {
    name: "nid",
    type: "text",
    sortable: true,
    searchable: true,
  },
  {
    name: "primary_phone",
    type: "text",
    sortable: true,
    searchable: true,
  },
  {
    name: "secondary_phone",
    type: "text",
    sortable: true,
    searchable: true,
  },
  {
    name: "email",
    type: "text",
    sortable: true,
    searchable: true,
  },
  {
    name: "sale_commission",
    type: "text",
    sortable: true,
    searchable: true,
  },
  {
    name: "dob",
    type: "date",
    sortable: true,
    searchable: true,
  },
  {
    name: "notes",
    sortable: true,
    searchable: true,
    type: "text",
  },
];

export default function List({ title, query }) {
  return (
    <ResourceListPage
      url="contractors"
      columns={columns}
      title={title}
      baseQuery={query}
    />
  );
}
