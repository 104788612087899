import React from "react";
import MainApp from "commons/components/MainApp";
import Arabic from "./languages/arabic";
import English from "./languages/english";
import Dashboard from "./pages/base/Dashboard";

export default function App() {
  return (
    <MainApp
      arabic={Arabic}
      english={English}
      Dashboard={Dashboard}
      showLangSelect
    />
  );
}
