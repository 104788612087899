import React from "react";
import PageCard from "commons/components/PageCard";
import { Grid, Typography } from "@material-ui/core";
import useTranslate from "commons/hooks/useTranslate";
import useControlledResourcePage from "commons/hooks/useControlledResourcePage";
import Editor from "./Editor";
import ResourceToolbar from "commons/components/ResourceToolbar";
import LinkTabs from "commons/components/LinkTabs";
import ProductConnector from "commons/components/ProductConnector";
import { FormNumberField } from "commons/components/FormNumberField";
import {
  PrintTemplate,
  usePrintManager,
} from "commons/components/PrintManager";

const base = "facilities";

export default function Controller() {
  const { current, send, model, updateModel, id } = useControlledResourcePage(
    base
  );
  const { active, onPrintRequest, onPrintCompleted } = usePrintManager();
  const hasResource = Boolean(model.id);

  return (
    <PrintTemplate active={active} onPrintCompleted={onPrintCompleted}>
      <PageCard>
        <ResourceToolbar
          title={base}
          current={current}
          send={send}
          onPrintRequest={onPrintRequest}
          accessGroup
        />
        <LinkTabs
          show={hasResource}
          tabs={[
            {
              name: "editor",
              path: `/s/facilities/${id}`,
              component: (
                <Editor
                  current={current}
                  send={send}
                  updateModel={updateModel}
                />
              ),
            },
            {
              name: "products",
              path: `/s/facilities/${id}/products`,
              component: <Products id={id} />,
            },
            {
              name: "services",
              path: `/s/facilities/${id}/services`,
              component: <Services id={id} />,
            },
          ]}
        />
      </PageCard>
    </PrintTemplate>
  );
}

function Products({ id }) {
  const { t } = useTranslate();
  return (
    <ProductConnector
      id={id}
      relation="facility-product"
      relationKey="facility_id"
      model={{
        facility_id: id,
        max_level: 0,
        min_level: 0,
        max_in_sale: 0,
        reorder_level: 0,
      }}
      header={
        <>
          <Grid item sm={3}>
            <Typography variant="subtitle1">{t("max_level")}</Typography>
          </Grid>
          <Grid item sm={3}>
            <Typography variant="subtitle1">{t("min_level")}</Typography>
          </Grid>
          <Grid item sm={3}>
            <Typography variant="subtitle1">{t("max_in_sale")}</Typography>
          </Grid>
          <Grid item sm={3}>
            <Typography variant="subtitle1">{t("reorder_level")}</Typography>
          </Grid>
        </>
      }
      rowRenderer={(record, onItemChange) => (
        <>
          <FormNumberField
            grid={3}
            size="small"
            value={record.max_level}
            onChange={onItemChange("max_level")}
          />
          <FormNumberField
            grid={3}
            size="small"
            value={record.min_level}
            onChange={onItemChange("min_level")}
          />
          <FormNumberField
            grid={3}
            size="small"
            value={record.max_in_sale}
            onChange={onItemChange("max_in_sale")}
          />
          <FormNumberField
            grid={3}
            size="small"
            value={record.reorder_level}
            onChange={onItemChange("reorder_level")}
          />
        </>
      )}
    />
  );
}

function Services({ id }) {
  const { t } = useTranslate();
  return (
    <ProductConnector
      services
      id={id}
      relation="facility-product"
      relationKey="facility_id"
      model={{
        facility_id: id,
        max_level: 0,
        min_level: 0,
        max_in_sale: 0,
        reorder_level: 0,
      }}
      header={
        <>
          <Grid item sm={12}>
            <Typography variant="subtitle1">{t("max_in_sale")}</Typography>
          </Grid>
        </>
      }
      rowRenderer={(record, onItemChange) => (
        <>
          <FormNumberField
            size="small"
            value={record.max_in_sale}
            onChange={onItemChange("max_in_sale")}
          />
        </>
      )}
    />
  );
}
