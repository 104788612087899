import React from "react";
import ResourceListPage from "commons/components/ResourceListPage";

const columns = [
  {
    name: "name",
    type: "text",
    sortable: true,
    searchable: true,
  },
  {
    name: "address",
    type: "text",
    sortable: true,
    searchable: true,
  },
  {
    name: "nid",
    type: "text",
    sortable: true,
    searchable: true,
  },
  {
    name: "primary_phone",
    type: "text",
    sortable: true,
    searchable: true,
  },
  {
    name: "secondary_phone",
    type: "text",
    sortable: true,
    searchable: true,
  },
  {
    name: "email",
    type: "text",
    sortable: true,
    searchable: true,
  },
  {
    name: "sale_commission",
    type: "text",
    sortable: true,
    searchable: true,
  },
  {
    name: "dob",
    type: "date",
    sortable: true,
    searchable: true,
  },
  {
    name: "joined",
    type: "date",
    sortable: true,
    searchable: true,
  },
  {
    name: "terminated",
    type: "date",
    sortable: true,
    searchable: true,
  },
  {
    name: "department",
    type: "text",
    sortable: true,
    searchable: true,
    searchKey: "departments.name",
  },
  {
    name: "grade",
    type: "text",
    sortable: true,
    searchable: true,
    searchKey: "grades.name",
  },
  {
    name: "qualifications",
    sortable: true,
    searchable: true,
    type: "text",
  },
  {
    name: "custody",
    sortable: true,
    searchable: true,
    type: "text",
  },
  {
    name: "notes",
    sortable: true,
    searchable: true,
    type: "text",
  },
];

export default function List({ title, query }) {
  return (
    <ResourceListPage
      url="employees"
      columns={columns}
      title={title}
      baseQuery={query}
    />
  );
}
