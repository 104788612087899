import { useEffect, useMemo } from "react";
import useResourcesByQuery from "commons/hooks/useResourcesByQuery";
import { eqProps, prop, uniqWith } from "ramda";

export default function useSessionsManager(facility, service) {
  const [facilities] = useResourcesByQuery("facilities", true);
  //   const [customers] = useResourcesByQuery("customers", true);
  const [services, sendServices] = useResourcesByQuery("sale-lines");
  const [sessions, sendSessions] = useResourcesByQuery("sessions");
  const [attendances, sendAttendances] = useResourcesByQuery(
    "session-attendances"
  );

  useEffect(() => {
    if (facility) {
      sendServices("SET_QUERY", {
        query: {
          "sales.facility_id": facility,
          "products.type": "SESSIONS",
        },
      });
    }
  }, [facility, sendServices]);

  useEffect(() => {
    if (service) {
      sendSessions("SET_QUERY", {
        query: {
          product_id: service,
        },
      });
    }
  }, [service, sendSessions]);

  useEffect(() => {
    if (sessions.length > 0) {
      const ids = sessions.map(prop("id"));
      sendAttendances("SET_QUERY", {
        query: {
          session_id: {
            $in: ids,
          },
        },
      });
    }
  }, [sessions, sendAttendances]);

  const uniqServices = useMemo(() => {
    return uniqWith(eqProps("product_id"), services);
  }, [services]);

  const customers = useMemo(() => {
    return uniqWith(
      eqProps("customer_id"),
      services
        .filter((curr) => curr.product_id === service)
        .map((curr) => ({
          customer_id: curr.customer_id,
          customer: curr.customer,
        }))
    );
  }, [service, services]);

  //   const customersByKey = useMemo(() => {
  //     return customers.reduce(
  //       (acc, curr) => ({ ...acc, [curr.id]: curr.name }),
  //       {}
  //     );
  //   }, [customers]);

  return {
    facilities,
    services: uniqServices,
    customers,
    sessions,
    attendances,
  };
}
