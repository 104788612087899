import React, { useState } from "react";
import ReportManager from "commons/components/ReportManager";
import useResourcesByQuery from "commons/hooks/useResourcesByQuery";
import { Button, ButtonGroup, IconButton, Tooltip } from "@material-ui/core";
import { CallMerge, CallSplit } from "@material-ui/icons";
import useTranslate from "commons/hooks/useTranslate";

const filters = [
  { type: "filter", name: "facilities", key: "facility_id" },
  {
    type: "filter",
    name: "products",
    key: "product_id",
    filters: { stockable: true },
  },
  {
    type: "filter",
    name: "categories",
    key: "category_id",
  },
  {
    type: "custom",
    name: "direction",
    key: "direction",
    Component: DirectionSelect,
  },
];

export function DirectionSelect({ onChange }) {
  const { t } = useTranslate();
  const [mode, setMode] = useState("NONE");

  const changeQuery = (selected) => {
    if (selected === "NONE") {
      onChange({ quantity: null, operation_type: null });
    }
    if (selected === "ENTER") {
      onChange({
        quantity: { $gt: 0 },
        operation_type: { $in: ["transfer", "purchase"] },
      });
    }
    if (selected === "EXIT") {
      onChange({
        quantity: { $lt: 0 },
        operation_type: { $in: ["transfer", "sale", "compositeOut"] },
      });
    }
  };

  return (
    <ButtonGroup
      size="large"
      color="primary"
      aria-label="large outlined primary button group"
    >
      <Button
        variant={mode === "ENTER" ? "contained" : "outlined"}
        onClick={() => {
          const selected = mode === "ENTER" ? "NONE" : "ENTER";
          setMode(selected);
          changeQuery(selected);
        }}
      >
        {t("enter_only")}
      </Button>
      <Button
        variant={mode === "EXIT" ? "contained" : "outlined"}
        onClick={() => {
          const selected = mode === "EXIT" ? "NONE" : "EXIT";
          setMode(selected);
          changeQuery(selected);
        }}
      >
        {t("exit_only")}
      </Button>
    </ButtonGroup>
  );
}

export const columns = [
  {
    name: "facility",
    type: "text",
  },
  {
    name: "product_name",
    type: "text",
  },
  {
    name: "product_code",
    type: "text",
  },
  {
    name: "serial",
    type: "text",
  },
  {
    name: "mfg_date",
    type: "date",
  },
  {
    name: "exp_date",
    type: "date",
  },
  {
    name: "value",
    type: "money",
  },
  {
    name: "quantity",
    type: "number",
  },
  {
    name: "total_value",
    type: "money",
  },
];

export const mergeColumns = [
  {
    name: "facility",
    type: "text",
  },
  {
    name: "product_name",
    type: "text",
  },
  {
    name: "product_code",
    type: "text",
  },
  {
    name: "value",
    type: "money",
  },
  {
    name: "quantity",
    type: "number",
  },
  {
    name: "total_value",
    type: "money",
  },
];

export function useInventoryReport() {
  const [facilities] = useResourcesByQuery("facilities", true);
  const [products] = useResourcesByQuery("products", true);

  const facilitiesByID = facilities.reduce(
    (acc, curr) => ({ ...acc, [curr.id]: curr }),
    {}
  );

  const productsByID = products.reduce(
    (acc, curr) => ({ ...acc, [curr.id]: curr }),
    {}
  );

  const transformer = (records) =>
    records.map((record, index) => ({
      ...record,
      id: index,
      facility: facilitiesByID[record.facility_id].name,
      product_name: productsByID[record.product_id].name,
      product_code: productsByID[record.product_id].code,
    }));

  const mergeTransformer = (records) =>
    records.map((record, index) => ({
      value: record.total_value / record.quantity,
      quantity: record.quantity,
      total_value: record.total_value,
      id: index,
      facility: facilitiesByID[record.facility_id].name,
      product_name: productsByID[record.product_id].name,
      product_code: productsByID[record.product_id].code,
    }));

  return { transformer, mergeTransformer };
}

export default function InventoryLevels() {
  const { transformer, mergeTransformer } = useInventoryReport();
  const [merge, setMerge] = useState(false);
  const { t } = useTranslate();

  return merge ? (
    <ReportManager
      key="stock-totals"
      filterByDate
      dateField="fulfilled"
      url="stock-totals"
      title="stock-levels"
      filters={filters}
      columns={mergeColumns}
      recordsFunc={mergeTransformer}
      totals={["total_value"]}
      toolbarExtras={
        <Tooltip title={t("split")}>
          <IconButton onClick={() => setMerge(false)}>
            <CallSplit />
          </IconButton>
        </Tooltip>
      }
    />
  ) : (
    <ReportManager
      key="stock-levels"
      filterByDate
      dateField="fulfilled"
      url="stock-levels"
      title="stock-levels"
      filters={filters}
      columns={columns}
      recordsFunc={transformer}
      totals={["total_value"]}
      toolbarExtras={
        <Tooltip title={t("merge")}>
          <IconButton onClick={() => setMerge(true)}>
            <CallMerge />
          </IconButton>
        </Tooltip>
      }
    />
  );
}
