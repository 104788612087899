import React, {useState} from "react";
import ReportManager from "commons/components/ReportManager";
import useTranslate from "commons/hooks/useTranslate";
import {IconButton, Tooltip, Typography, Button} from "@material-ui/core";
import { DeleteForever, Delete } from "@material-ui/icons";
import api from "../../../helpers/api";
import dayjs from "dayjs";
import {useMutation} from "react-query";
import LoadingIndicator from "../../../components/LoadingIndicator";
import ErrorAlert from "../../../components/ErrorAlert";

const columns = [
  {
    name: "auditable_type",
    type: "translate",
  },
  {
    name: "auditable_id",
    type: "text",
  },
  {
    name: "event",
    type: "translate",
  },
  {
    name: "user",
    type: "text",
  },
];


function deleteResources() {
  return api.service("audit-logs").remove(null, {
    query: {
      date: {
        $lte: dayjs().startOf("day").subtract(3, "month"),
      },
    },
  });
}
export default function AuditReport() {
  const { t } = useTranslate();
  const [showRemove, setShowRemove] = useState(false);

  const { mutate, isLoading, error } = useMutation(deleteResources, {
    onSuccess: () => {
      setShowRemove(false);
    },
  });

  const filters = [
    {
      type: "filter",
      name: "users",
      key: "user_id",
      extras: { optionLabel: "username" },
    },
    {
      type: "select",
      name: "event",
      key: "event",
      options: [
        { id: "create", name: t("create") },
        { id: "update", name: t("update") },
        { id: "remove", name: t("remove") },
      ],
    },
  ];

  return <ReportManager
        url="audit-logs"
        title="audit-logs"
        filters={filters}
        columns={columns}
        filterByDate
        dateField="date"
        toolbarExtras={
          <Tooltip title={t("delete")}>
            <IconButton onClick={() => setShowRemove(true)}>
              <DeleteForever />
            </IconButton>
          </Tooltip>
        }
      >
        {showRemove && (
            <div>
              <LoadingIndicator show={isLoading} />
              <ErrorAlert error={error} />
              <Typography variant="h5">
                {t('delete_audit_headline')}
              </Typography><br/>
              <Typography variant="body1">
                {t('delete_audit_message')}
              </Typography>
              <br/>
              <Button onClick={mutate} variant="contained" color="primary" startIcon={<Delete />}>{t('delete')}</Button>
            </div>
        )}
      </ReportManager>;
}
