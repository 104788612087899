import React from "react";
import ReportManager from "commons/components/ReportManager";
import { IconButton } from "@material-ui/core";
import { Link as RouterLink } from "react-router-dom";
import { Person } from "@material-ui/icons";

const filters = [
  { type: "filter", name: "facilities", key: "facility_id" },
  {
    type: "filter",
    name: "products",
    key: "product_id",
    filters: { stockable: true },
  },
];

const columns = [
  {
    name: "operation_type",
    type: "translate",
  },
  {
    name: "operation_id",
    type: "text",
  },
  {
    name: "facility",
    type: "text",
  },
  {
    name: "code",
    type: "text",
  },
  {
    name: "product",
    type: "text",
  },
  {
    name: "fulfilled",
    type: "datetime",
  },
  {
    name: "serial",
    type: "text",
  },
  {
    name: "mfg_date",
    type: "date",
  },
  {
    name: "exp_date",
    type: "date",
  },
  {
    name: "value",
    type: "money",
  },
  {
    name: "quantity",
    type: "number",
  },
  {
    name: "total_value",
    type: "money",
  },
];

export default function InventoryMovements() {
  return (
    <ReportManager
      filterByDate
      dateField="fulfilled"
      url="stocks"
      title="movements"
      filters={filters}
      columns={columns}
      totals={["quantity", "total_value"]}
      toolbarExtras={
        <IconButton component={RouterLink} to={`/s/reports/serial-by-customer`}>
          <Person />
        </IconButton>
      }
    />
  );
}
