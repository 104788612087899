import React from "react";
import ReportManager from "commons/components/ReportManager";
import { columns, useInventoryReport } from "./InventoryLevels";
import useResourcesByQuery from "commons/hooks/useResourcesByQuery";
import { compose } from "ramda";

const filters = [
  { type: "filter", name: "facilities", key: "facility_id" },
  {
    type: "filter",
    name: "products",
    key: "product_id",
    filters: { stockable: true },
  },
];

const checkForShortages = (connections) => (records) => {
  return records.filter((rec) => {
    const connection = connections.find(
      (con) =>
        con.facility_id === rec.facility_id && con.product_id === rec.product_id
    );
    if (connection) {
      return Number(connection.reorder_level) > Number(rec.quantity);
    }
    return false;
  });
};
export default function InventoryShortages() {
  const [connections] = useResourcesByQuery("facility-product", true);
  const { transformer } = useInventoryReport();

  return (
    <ReportManager
      url="stock-totals"
      title="shortages"
      filters={filters}
      columns={columns}
      recordsFunc={compose(checkForShortages(connections), transformer)}
    />
  );
}
