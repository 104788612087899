import React from "react";
import ReportManager from "commons/components/ReportManager";
import { IconButton } from "@material-ui/core";
import { Link as RouterLink } from "react-router-dom";
import { KeyboardReturn } from "@material-ui/icons";

const filters = [
  { type: "filter", name: "customers", key: "customer_id" },
  {
    type: "filter",
    name: "products",
    key: "product_id",
    filters: { stockable: true },
  },
  { type: "text", name: "serial", key: "serial" },
];

const columns = [
  {
    name: "fulfilled",
    type: "datetime",
  },
  {
    name: "facility",
    type: "text",
  },
  {
    name: "reference",
    type: "text",
  },
  {
    name: "customer",
    type: "text",
  },
  {
    name: "phone",
    type: "text",
  },
  {
    name: "address",
    type: "text",
  },
  {
    name: "serial",
    type: "text",
  },
  {
    name: "code",
    type: "text",
  },
  {
    name: "product",
    type: "text",
  },
];

export default function SerialByCustomer() {
  return (
    <ReportManager
      filterByDate
      dateField="fulfilled"
      url="stock-serials"
      title="serialByCustomer"
      filters={filters}
      columns={columns}
      toolbarExtras={
        <IconButton component={RouterLink} to={`/s/reports/stock-movements`}>
          <KeyboardReturn />
        </IconButton>
      }
    />
  );
}
