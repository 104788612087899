import React, { useEffect, useMemo, useState } from "react";
import PageCard from "commons/components/PageCard";
import { useParams } from "react-router-dom";
import {
  Box,
  Fab,
  IconButton,
  Portal,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Toolbar,
  Typography,
  Zoom,
} from "@material-ui/core";
import useTranslate from "commons/hooks/useTranslate";
import { Check, Person } from "@material-ui/icons";
import {
  PrintButton,
  PrintTemplate,
  usePrintManager,
} from "commons/components/PrintManager";
import { DashboardShortcutPin } from "commons/components/DashboardShortcut";
import { Link as RouterLink } from "react-router-dom";
import {
  DurationFilter,
  useReportQuery,
  useReportController,
  ReportTable,
} from "commons/components/ReportManager";
import LoadingIndicator from "commons/components/LoadingIndicator";
import ErrorAlert from "commons/components/ErrorAlert";
import { TotalsGroup } from "commons/components/Balance";
import useResourcesByQuery from "commons/hooks/useResourcesByQuery";
import { sumField } from "commons/helpers/utils";
import api from "commons/helpers/api";

const operationColumns = [
  {
    name: "date",
    type: "datetime",
  },
  {
    name: "reference",
    type: "text",
  },
  {
    name: "currency",
    type: "text",
  },
  {
    name: "total",
    type: "money",
  },
  {
    name: "summary",
    type: "text",
  },
];

const paymentColumns = [
  {
    name: "date",
    type: "datetime",
  },
  {
    name: "currency",
    type: "text",
  },
  {
    name: "amount_in_currency",
    type: "money",
  },
  {
    name: "method",
    type: "translate",
  },
  {
    name: "reference",
    type: "text",
  },
];

export default function BalanceStatement({
  base = "customers",
  field = "customer_id",
  operation = "sales",
  payments_service = "customer-payments",
}) {
  const { id } = useParams();
  const { t } = useTranslate();
  const [persons] = useResourcesByQuery(base, true, {
    [`${base}.id`]: id,
  });
  const person = persons[0];
  const { active, onPrintRequest, onPrintCompleted } = usePrintManager();
  const [serverQuery, , duration, , setDuration] = useReportQuery({
    [field]: id,
  });
  const [policy, setPolicy] = useState(null);

  useEffect(() => {
    if (person && person.policy_id) {
      api.service("policies").get(person.policy_id).then(setPolicy);
    }
  }, [person]);

  const [operations, salesError, salesIsLoading, salesApply] =
    useReportController(operation, duration, "date");
  const [payments, paymentsError, paymentsIsLoading, paymentsApply] =
    useReportController(payments_service, duration, "date");

  const apply = () => {
    salesApply(serverQuery);
    paymentsApply(serverQuery);
  };

  const errors = [salesError, paymentsError].filter((er) => Boolean(er));
  const isLoading = [salesIsLoading, paymentsIsLoading].every((l) => l);

  const positive = payments.filter((p) => p.amount > 0);
  const negative = payments.filter((p) => p.amount < 0);

  const operationsDefaultCurrencyTotal = useMemo(() => {
    if (!policy) return 0;
    const totals = operations.map((o) => ({
      total: o.total * o.rate,
    }));
    return sumField("total")(totals) / 100;
  }, [operations, policy]);

  const rate = policy ? policy.currency.rate : 100;
  const operationsTotal =
    Math.round((operationsDefaultCurrencyTotal / rate) * 100) / 100;
  const positiveTotal =
    Math.round((sumField("amount")(positive) / rate) * 100) / 100;
  const negativeTotal =
    Math.round((sumField("amount")(negative) / rate) * 100) / 100;

  const balance =
    Math.round((positiveTotal + negativeTotal - operationsTotal) * 100) / 100;

  return (
    <PrintTemplate active={active} onPrintCompleted={onPrintCompleted}>
      <PageCard>
        <LoadingIndicator show={isLoading} />
        <ErrorAlert error={errors.length > 0 ? errors[0] : null} />
        <Toolbar disableGutters className="no-print">
          <Typography style={{ flex: "1 1 100%" }} variant="h4">
            {t("balance-statement")}
          </Typography>
          <IconButton component={RouterLink} to={`/s/${base}/${id}`}>
            <Person />
          </IconButton>
          <PrintButton
            filterFunc={(item) => item.reports === true}
            onPrintRequest={onPrintRequest}
          />
          <DashboardShortcutPin title="balance-statement" />
          {/* <ExcelExporterButton
          headers={columns.map(prop("name"))}
          data={records}
          filename={`${t(title)}-${t("report")}`}
        /> */}
        </Toolbar>
        <DurationFilter duration={duration} onChange={setDuration} />
        {person && (
          <Table>
            <TableBody>
              <TableRow>
                <TableCell>
                  <strong>{t("name")}</strong>
                </TableCell>
                <TableCell>{person.name}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <strong>{t("address")}</strong>
                </TableCell>
                <TableCell>{person.address}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <strong>{t("phone")}</strong>
                </TableCell>
                <TableCell>{person.primary_phone}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        )}
        <br />
        <TotalsGroup
          labels={[operation, "payments", "refunds", "balance"]}
          values={{
            [operation]: operationsTotal,
            payments: positiveTotal,
            refunds: negativeTotal,
            balance: balance,
          }}
        />
        <div style={{ margin: "2rem" }}></div>
        <h2>{t(operation)}</h2>
        <ReportTable data={operations} columns={operationColumns} />
        <div style={{ margin: "2rem" }}></div>
        <h2>{t("payments")}</h2>
        <ReportTable data={positive} columns={paymentColumns} />
        {negative.length > 0 && (
          <>
            <div style={{ margin: "2rem" }}></div>
            <h2>{t("refunds")}</h2>
            <ReportTable data={negative} columns={paymentColumns} />
          </>
        )}
        <div style={{ margin: "3rem" }}></div>
        <Portal>
          <Box position="fixed" bottom={24} right={24}>
            <Zoom in style={{ transitionDelay: "250ms" }}>
              <Fab color="primary" onClick={apply}>
                <Check />
              </Fab>
            </Zoom>
          </Box>
        </Portal>
      </PageCard>
    </PrintTemplate>
  );
}
