import React from "react";
import useControlledResourcePage from "commons/hooks/useControlledResourcePage";
import ResourceSinglePage from "commons/components/ResourceSinglePage";
import FormTextField from "commons/components/FormTextField";
import TemplateBuilder from "./TemplateBuilder";
import useTranslate from "commons/hooks/useTranslate";
import FormSelectField from "commons/components/FormSelectField";

const base = "accounting-reports";
const route = "reports/accounting-reports-templates";

export default function Single() {
  const { t } = useTranslate();
  const {
    current,
    send,
    model,
    updateModel,
    mergeModel,
    rules,
  } = useControlledResourcePage(base, {}, true, route);

  const onTypeChange = (value) => {
    mergeModel({
      type: value,
      body: [[], []],
    });
  };

  return (
    <ResourceSinglePage
      title={base}
      current={current}
      send={send}
      route={route}
    >
      <FormTextField
        grid={6}
        label="name"
        value={model.name}
        onChange={updateModel("name")}
        error={rules.name}
      />
      <FormSelectField
        grid={6}
        label="type"
        options={[
          { id: "two-columns", name: t("two-columns") },
          { id: "three-columns", name: t("three-columns") },
          { id: "four-columns", name: t("four-columns") },
        ]}
        value={model.type}
        onChange={onTypeChange}
        error={rules.type}
      />
      {model.type && (
        <TemplateBuilder
          value={
            typeof model.body === "string"
              ? JSON.parse(model.body)
              : model.body || []
          }
          onChange={(val) => updateModel("body")(JSON.stringify(val))}
          error={rules.body}
          type={model.type}
        />
      )}
    </ResourceSinglePage>
  );
}
