import React from "react";
import ResourceListPage from "commons/components/ResourceListPage";
import { PlaylistAddCheck } from "@material-ui/icons";
import { IconButton } from "@material-ui/core";
import { Link } from "react-router-dom";

const columns = [
  {
    name: "service",
    type: "text",
    sortable: true,
    searchable: true,
    searchKey: "products.name",
  },
  {
    name: "facility",
    type: "text",
    sortable: true,
    searchable: true,
    searchKey: "facilities.name",
  },
  {
    name: "service_location",
    type: "text",
    sortable: true,
    searchable: true,
    searchKey: "service_locations.name",
  },
  {
    name: "starts",
    type: "datetime",
    sortable: true,
    searchable: true,
  },
  {
    name: "ends",
    type: "datetime",
    sortable: true,
    searchable: true,
  },
];

export default function List() {
  return (
    <ResourceListPage
      url="sessions"
      columns={columns}
      actions={
        <IconButton component={Link} to={`/s/session-attendances`}>
          <PlaylistAddCheck />
        </IconButton>
      }
    />
  );
}
